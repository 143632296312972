import { Navigate, Route, Routes } from "react-router-dom";
import AllData from "../../../board/layouts/datalistLayouts/allData/AllData";
import DataExport from "../../../board/layouts/datalistLayouts/dataExport/DataExport";
import DayData from "../../../board/layouts/datalistLayouts/dayData/DayData";
import SingleData from "../../../board/layouts/datalistLayouts/dayData/SingleData";
import Blocks from "../../../board/layouts/homeLayouts/Blocks/Blocks";
import GraphView from "../../../board/layouts/homeLayouts/graphsView/GraphView";
import AddUser from "../../../board/layouts/usersLayouts/addUser/AddUser";
import UsersList from "../../../board/layouts/usersLayouts/userList/UsersList";
import Datalist from "../../../board/pages/datalist/Datalist";
import Home from "../../../board/pages/home/Home";
import Marketplace from "../../../board/pages/marketplace/Marketplace";
import NoMatch from "../../../board/pages/nomatch/NoMatch";
import PrivateKeys from "../../../board/pages/privateKeys/PrivateKeys";
import Reports from "../../../board/pages/reports/Reports";
import UsersAdmin from "../../../board/pages/users/admin view/UsersAdmin";
import UsersTypical from "../../../board/pages/users/userView/UsersTypical";
import ProtectedRoute from "../../../components/ProtectedRoute";
import {
  MainRoutePaths,
  SubRoutePaths,
} from "../../../resources/utils/enums/CommonEnums";
import Topbar from "../topbar/Topbar";
import {
  BoardContainer,
  BoardWrapper,
  RightbarContainer,
  RightbarWrppaer,
} from "./RightbarSC";

const Rightbar = () => {
  return (
    <RightbarContainer>
      <RightbarWrppaer>
        <Topbar />
        <BoardContainer>
          <BoardWrapper>
            <Routes>
              <Route path={MainRoutePaths.HOME} element={<ProtectedRoute />}>
                {/**  <Route index={true} element={<Home />} />  */}
                <Route path={MainRoutePaths.HOME} element={<Home />}>
                  <Route index element={<Blocks />} />
                  <Route
                    path={`${SubRoutePaths.GRAPH_VIEW}=:sensorId`}
                    element={<GraphView />}
                  />
                </Route>
              </Route>
              <Route path={MainRoutePaths.PRIVATE_KEYS} element={<ProtectedRoute />}>
                  <Route  index element={<PrivateKeys />}  />
              </Route>

              <Route path={MainRoutePaths.USERS} element={<ProtectedRoute />}>
                {/**   <Route path={MainRoutePaths.USERS} element={<UserPageSwitcher />} /> */}
                <Route path={MainRoutePaths.USERS} element={<UsersAdmin />}>
                  <Route>
                    <Route index element={<UsersList />} />
                    <Route path="addUser" element={<AddUser />} />
                  </Route>
                  {/** Unmatched route */}
                  <Route path="*" element={<Navigate to="/" />} />
                </Route>
              </Route>
              <Route
                path={MainRoutePaths.USERPAGE}
                element={<ProtectedRoute />}
              >
       
                <Route
                  path={MainRoutePaths.USERPAGE}
                  element={<UsersTypical />}
                />
              </Route>

              <Route
                path={MainRoutePaths.DATA_LIST}
                element={<ProtectedRoute />}
              >
                <Route path={MainRoutePaths.DATA_LIST} element={<Datalist />}>
                  <Route path="" element={<AllData />}>
                    <Route path="sensor&:sensorId" element={<DayData />}>
                      <Route path="day&:day" element={<SingleData />} />
                    </Route>
                  </Route>

                  <Route
                    path={SubRoutePaths.DATA_EXPORT}
                    element={<DataExport />}
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Route>
              </Route>
              <Route path={MainRoutePaths.REPORTS} element={<ProtectedRoute />}>
                <Route path={MainRoutePaths.REPORTS} element={<Reports />} />
              </Route>
              <Route
                path={MainRoutePaths.MARKETPLACE}
                element={<ProtectedRoute />}
              >
                <Route
                  path={MainRoutePaths.MARKETPLACE}
                  element={<Marketplace />}
                />
              </Route>
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </BoardWrapper>
        </BoardContainer>
      </RightbarWrppaer>
    </RightbarContainer>
  );
};

export default Rightbar;
