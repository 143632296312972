import { HomePageContainer, LoaderWrapper } from "./HomeSC";
import useHome from "./UseHome";
import Loader from "../../../resources/customs/loader/Loader";
import { Outlet } from "react-router-dom";
import { HomeOutletProps } from "../../../resources/utils/interfaces/OutletRelatedInterfaces";

const Home = () => {
  const { dataStatus, loading } = useHome();
  const { data } = dataStatus;
  const { latestData } = data;
  
  if (loading)
    return (
      <LoaderWrapper>
        <Loader scale={1.3} />
      </LoaderWrapper>
    );
  return (

    <HomePageContainer>

      <Outlet context={{ blockProps: { latestData } } as HomeOutletProps} />
    </HomePageContainer>

  );
};

export default Home;
