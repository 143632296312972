import { UserRoles } from "../enums/CommonEnums";
import { AttrsJwt } from "../interfaces/CommonInterfaces";




export const getAttrs = (object: any): AttrsJwt => {
  const role = JSON.parse(object.reloadUserInfo.customAttributes)
    .role as UserRoles;
  const name = JSON.parse(object.reloadUserInfo.customAttributes)
  .name as UserRoles;
  return {role, name};
};

interface UserCreate {
  name: string;
  company: string;
  email: string;
  password: string;
  role: UserRoles;
}
export const userConstruct = (obj: any): UserCreate => {
  // for (let i in UserCreate)
  return {
    name: obj.name,
    email: obj.email,
    password: obj.password,
    role: obj.role,
    company: obj.company,
  };
};

export const  groupBy = (arr: any[], property: string) => {
  return arr.reduce(function(memo, x) {
    if (!memo[x[property]]) { memo[x[property]] = []; }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export const getDozen = (n : number) : number => {
  var r = n%10;
  //if (n % 2 === 0) return n
  if(r>4) return Math.ceil(n/10) *10;
  else return (Math.floor(n/10) *10) + 10;
}

export const parseJwt = (token : string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function isTokenExpired(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const { exp } = JSON.parse(jsonPayload);
  const expired = Date.now() >= exp * 1000
  return expired
}

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
}

export const getDateWithoutTime = (date: Date) => {
    return date.getFullYear()+'-'+(padTo2Digits(date.getMonth() + 1))+'-'+padTo2Digits(date.getDate());
} 

export const getTimeOnly = (dati : Date) : string => {
  return padTo2Digits(dati.getHours())+":"+padTo2Digits(dati.getMinutes())+":"+padTo2Digits(dati.getSeconds());
}

export const removeDuplicates = (array: any[], key: string) => {
  let check : any= {};
  let res = [];
  for(let i=0; i<array.length; i++) {
      if(!check[array[i][key]]){
          check[array[i][key]] = true;
          res.push(array[i]);
      }
  }
  return res;
}


