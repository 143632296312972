import styled, { css } from "styled-components";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import ANIMATIONS from "../../../resources/utils/styles/OtherCss";

export let Header = styled.h1`
  ${(props) => props.theme.font}
`;

export let Text = styled.p`
  ${(props) => props.theme.font}
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
`;

export const TopLayer = styled.div`
  width: 95%;
  margin-top: 10px;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
`;

export const IconBox: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 35px;
  border: 1px solid #f9fafc;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  transition: all 300ms ease-out;
  ${({ selected }: any) =>
    selected && "background: #e2e2e2;transform: scale(1.2); opacity: 0.9;"}
  &:hover {
    opacity: 0.7;
    background: #e2e2e2;
    transform: scale(1.1);
  }
`;

export const SearchBoxWrapper = styled.div`
  height: 100%;
  border: 1px solid #f9fafc;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  width: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > .--icon {
    margin-left: 15px;
    color: #868fa0;
  }
`;

export const SearchInput = styled.input`
  width: calc(100% - 70px);
  background: transparent;
  outline: none;
  border: none;
`;

export const TableCenter = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // background: red;
`;

export const TableHeaderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e9edf5;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
`;

const headerCellCustom = css`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
`;

export const HeaderCell = styled.div`
  ${headerCellCustom}
 

`;

export const SelectCellWrapper = styled.div`
  ${headerCellCustom}
  flex: 0.3;
  position: relative;
`; 


export const SelectCell: any = styled.div`
  ${headerCellCustom}
  //background: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
 // flex: 0.3;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 350ms ease-out;
  ${ANIMATIONS.selectAnimation}
  ${({ isFullySelect }: any) =>
    isFullySelect &&
    "  & > .select--box {border:#BDBDBD;" +
      "&:hover {background:  #ffffff; & > .circle--icon {color:#BDBDB; }} & >  .circle--icon {color: #BDBDBD;} }"}
`;

export const TableRowContainer: any = styled.div`
  width: 100%;
  // height: 30px;
  height: 8.5%;
  display: flex;
  //padding: 0 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  transition: all 350ms ease-out;
  border-radius: 0px;
  cursor: pointer;
  /** 
  background: ${({ selectedRow, multiSelectRow }: any) =>
    multiSelectRow ? "#BDBDBD" : selectedRow && "#1151F5"};
  & > div > h1 {
    color: ${({ selectedRow }: any) => selectedRow && "white"};
  } **/

  ${({ selectedRow, multiSelectRow }: any) =>
    multiSelectRow
      ? "background:#BDBDBD;"
      : selectedRow && "background: #1151F5; & > div > h1 {color:white;}"}
  &:hover {
    ${({ multiSelectRow }: any) =>
      !multiSelectRow &&
      "background: #1151f5; opacity: 0.5; & > div > h1 {color: white;}"}
  }
`;

export const TableFooter = styled.div`
  width: 100%;
  height: 35px;
  position: absolute;
  // border: 3px;
  bottom: 0px;
  //right: 0px;
  background: #e5e5e5;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(1.5px);
`;

export const SecondRowSection = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  & > .arrow--icon {
    transform: scale(0.8);
    padding: 5px;
    background: #fff;
    color: #3a3a3a;
    border-radius: 5px;
    transition: all 300ms ease-out;
    &:hover {
      background: #3a3a3a;
      color: #fff;
    }
  }
`;

const arrowCustom: any = css`
  transform: scale(0.8);
  padding: 5px;
  // background: #fff;
  background: ${({ disabled }: any) => (disabled ? "#b8b4b4" : "#fff")};
  color: #3a3a3a;
  border-radius: 5px;
  transition: all 300ms ease-out;
  &:hover {
    // background: #3a3a3a;
    background: ${({ disabled }: any) => !disabled && "#3a3a3a"};
    //  color: #fff;
    color: ${({ disabled }: any) => !disabled && "#fff"};
  }
`;

export const ArrowBack: any = styled(ArrowBackIosNew)`
  ${arrowCustom}
`;

export const ArrowFront: any = styled(ArrowForwardIos)`
  ${arrowCustom}
`;
