import styled from "styled-components";

export let Header = styled.h1`
  ${(props) => props.theme.font}
`;

export const AddUserContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: userlistanimation;
  animation-duration: 0.3s;

  @keyframes userlistanimation {
    0% {
      opacity: 0.4;
      left: 100px;
    }
    //  50%  { left:20px;}
    100% {
      opacity: 1;
      left: 0px;
    }
  }
`;

export const AddUserWrapper = styled.form`
  width: 50%;
  min-width: 550px;
  height: 100%;
  position: relative;
  // background: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Inputs = styled.div`
  //margin-top: 10px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > .--labelrole {
    margin-top: 15px;
  }
  & > .input--form {
    height: 28px;
    margin-top: 15px;
  }
`;

export const RadioSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  margin-top: 21px;
`;

export const RadioLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const RadioLabel = styled.h1`
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  font-family: "Montserrat";
`;

export const ButtonSection = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const LoadErrorSection = styled.div`
  position: absolute;
  bottom: 0;
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: red;
`;

export const ErrorContainer = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1151f5;
  color: #ffff;
  font-size: 15px;
`;
