import { Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";



interface UseSelectProps {
    showDropDownMenu: boolean;
    setShowDropDownMenu: Dispatch<SetStateAction<boolean>>;
    selectedValue: string;
    handleSelect:(value: any) => void;
    ref: RefObject<any>;
  }
interface UseSelectInbound {
  onChange: (value: any , ...other: any) => any | void
}

const useSelect = ( {onChange} : UseSelectInbound) : UseSelectProps => {
    const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>('');

    const handleSelect = (value : any) => {
      if (value === selectedValue) {
        setSelectedValue('');
      }
      else setSelectedValue(value)
    }
    const ref = useDetectClickOutside({ onTriggered: () => setShowDropDownMenu(false) });
   useEffect(() => {
    console.log(selectedValue)
    onChange(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
   },[selectedValue])
    return {showDropDownMenu, setShowDropDownMenu, ref, selectedValue, handleSelect}

}


export default useSelect