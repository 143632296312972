import _ from "lodash";
import { TableState } from "../../../board/components/table/tableUtils/TypesEnums.";
import SelectedRows from "./SelectedRows";

interface TablesStatesProps {
  allSensorTable: TableState | null;
  dayTable: TableState | null;
  singleInfoTable: TableState | null;
}

class TablesStates implements TablesStatesProps {
  public allSensorTable: TableState | null = null;
  public dayTable: TableState | null = null;
  public singleInfoTable: TableState | null = null;

  /**
   *
   */
  constructor(obj?: TablesStatesProps) {
    if (obj) {
      const { allSensorTable, dayTable, singleInfoTable } = obj;
      this.allSensorTable = allSensorTable;
      this.dayTable = dayTable;
      this.singleInfoTable = singleInfoTable;
    }
  }

  public clearItems = (selectedRows: SelectedRows) => {
    const { daySelected, signleRowData, allSensorSelected } = selectedRows;
    if (this.allSensorTable)
      this.allSensorTable.multipleSelectedItems = _.filter(
        this.allSensorTable.multipleSelectedItems,
        (v) => {
          let found = _.find(
            allSensorSelected,
            (v1) => v1.sesnsorId === v.sesnsorId
          );
          return found ? false : true;
        }
      );
    if (this.dayTable)
      this.dayTable.multipleSelectedItems = _.filter(
        this.dayTable.multipleSelectedItems,
        (v) => {
          let found = _.find(daySelected, (v1) => v1.day === v.day);
          return found ? false : true;
        }
      );

    if (this.singleInfoTable)
      this.singleInfoTable.multipleSelectedItems = _.filter(
        this.singleInfoTable.multipleSelectedItems,
        (v) => {
          let found = _.find(signleRowData, (v1) => v1.Time === v.Time);
          return found ? false : true;
        }
      );
  };

  public checkMultipleSelect = (): boolean => {
    let returned = false;
    if (this.dayTable) {
      if (this.dayTable.multipleSelectedItems.length > 0) returned = true;
    }
    if (this.allSensorTable) {
      if (this.allSensorTable.multipleSelectedItems.length > 0) returned = true;
    }
    if (this.singleInfoTable) {
      if (this.singleInfoTable.multipleSelectedItems.length > 0)
        returned = true;
    }
    return returned;
  };

  public reset = () => {
    this.allSensorTable = null;
    this.dayTable = null;
    this.singleInfoTable = null
  }
}

export default TablesStates;
