import {
  ChangeEvent,
  Dispatch,
  useEffect,
  useState,
  SetStateAction,
} from "react";
import { AppContext } from "../../../../context/AppContext";
import { storage } from "../../../../resources/firebase";
import {
  BUserInfoState,
  PUserInfoState,
} from "../../../../resources/utils/enums/CommonEnums";
import {
  UserEditBasic,
  UserEditPassword,
} from "../../../../resources/utils/interfaces/CommonInterfaces";
import useFormSubmit, { SubmitCall } from "./useFormSubmit";

// State is gonna be used but can be chagned to Reducer later
interface UseUserTypicalProps {
  basicInfo: UserEditBasic;
  passwordInfo: UserEditPassword;
  controlBasicForm: (e: ChangeEvent<HTMLInputElement>) => void;
  controlPasswordForm: (e: ChangeEvent<HTMLInputElement>) => void;
  setFileImage: Dispatch<SetStateAction<File | null>>;
  fileImage: File | null;
  typicalPhotoURL: string | undefined | null;
  uploadFile: () => void;
  nameBefore: string;
  nullify: boolean;
  setNullify: Dispatch<SetStateAction<boolean>>;
  diplayImage: (file: File) => void;
  submitCalls: SubmitCall;
}

const useUsersTypical = (): UseUserTypicalProps => {
  const { userLogged, updateUserStatus } = AppContext();
  const [basicInfo, setBasicInfo] = useState<UserEditBasic>({
    name: "",
    phoneNumber: "+324 435 6565 43",
    emailAddress: "",
  });
  const [passwordInfo, setPasswordInfo] = useState<UserEditPassword>({
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  });
  const [fileImage, setFileImage] = useState<File | null>(null);
  const [nullify, setNullify] = useState<boolean>(false);
  useEffect(() => {
    setBasicInfo((current) => {
      return {
        ...current,
        name: userLogged.name,
        emailAddress: userLogged.email,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///
  const controlBasicForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as BUserInfoState;
    const value = e.target.value as string;
    setBasicInfo({ ...basicInfo, [name]: value });
  }; ///

  const controlPasswordForm = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as PUserInfoState;
    const value = e.target.value as string;
    setPasswordInfo({ ...passwordInfo, [name]: value });
  }; ///

  const uploadFile = () => {
    userLogged.uploadPicture(
      storage,
      fileImage as File,
      updateUserStatus,
      nullify
    );
    if (nullify) {
      setFileImage(null);
      setNullify(false);
    }
  };

  const diplayImage = (file: File) => {
    console.log("Function called");
    setFileImage(file as File);
  };
  const submitCalls = useFormSubmit({
    setBasicInfo,
    setPasswordInfo,
    basicInfo,
    passwordInfo,
  });

  return {
    basicInfo,
    controlBasicForm,
    passwordInfo,
    controlPasswordForm,
    setFileImage,
    fileImage,
    uploadFile,
    nameBefore: userLogged.name,
    typicalPhotoURL: userLogged.photoURL,
    nullify,
    setNullify,
    diplayImage,
    submitCalls,
  };
};

export default useUsersTypical;
