import { css } from "styled-components";

const ANIMATIONS = {
  basicpageAnimation: css`
    animation-name: exo;
    animation-duration: 0.3s;
    @keyframes exo {
      0% {
        opacity: 0.4;
        left: 100px;
      }
      //  50%  { left:20px;}
      100% {
        opacity: 1;
        left: 0px;
      }
    }
  `,
  graphAmimation: css`
    animation-name: example;
    animation-duration: 0.4s;

    @keyframes example {
      0% {
        left: 200px;
        opacity: 0.4;
      }
      100% {
        left: 0px;
        opacity: 1;
      }
    }
  `,
  selectAnimation: css`
    animation-name: example;
    animation-duration: 0.3s;

    @keyframes example {
      0% {
        right: 10px;
        opacity: 0.4;
      }
      100% {
        right: 0px;
        opacity: 1;
      }
    }
  `,
};


export const WEBIT = {
  scrollbar: css`
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: white;
      box-shadow: inset 0 0 2px grey;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background:rgba(159, 162, 180, 0.8);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `,
}; 

export default ANIMATIONS;
