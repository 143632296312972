import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getDozen } from "../../../resources/utils/hooks/Commonhooks";
import { TableProps } from "./Table";
import { tableDataStatusDefault } from "./tableUtils/Defaults";
import {
  DataStatusVars,
  Limits,
  TableDataStatus,
  TableState,
} from "./tableUtils/TypesEnums.";
import { UseTableHooks } from "./useTableHooks";

const { FilterWord, DataFiltered, SelectedRow } = DataStatusVars;

interface UseDataProps {
  filterWord: string;
  setFilterWord: (value: string) => void;
  dataFiltered: any[];
  selectedRow: any;
  handleSelectRow: (value: any) => void;
  seeLimit: Limits;
  increaseLimit: () => void;
  decreaseLimit: () => void;
  multipleSet: boolean | null;
  setMultipleSet: Dispatch<SetStateAction<boolean | null>>;
  addNewItemToMultiple: (value: any) => void;
  multipleSelectedItems: any[];
}

const useTable = (props: TableProps, _hooks: UseTableHooks): UseDataProps => {
  const { data, onSelect, tableState, setTableState, headers } = props;
  const [dataStatus, setDataStatus] = useState<TableDataStatus>(
    tableDataStatusDefault
  );
  const [seeLimit, setLimit] = useState<Limits>({
    min: 0,
    max: 9,
  });
  const [multipleSet, setMultipleSet] = useState<boolean | null>(null);
  const [multipleSelectedItems, setMultipleItemsSelected] = useState<any[]>([]);

  const addNewItemToMultiple = (value: any) => {
    if (_hooks._includes(multipleSelectedItems, value)) {
      setMultipleItemsSelected((current) => _hooks._delete(current, value));
    } else setMultipleItemsSelected((current) => [...current, value]);
  };

  // control data status method
  const controlDataStatus = (value: any, variable: DataStatusVars) => {
    setDataStatus((current) => {
      return { ...current, [variable]: value };
    });
  };
  const { filterWord, dataFiltered, selectedRow } = dataStatus;

  // Increase limit method
  const increaseLimit = () => {
    if (dataFiltered.length - 1 > seeLimit.max)
      setLimit({ max: seeLimit.max + 10, min: seeLimit.min + 10 });
  };
  // Decrease limit method
  const decreaseLimit = () => {
    if (seeLimit.min > 0)
      setLimit({ max: seeLimit.max - 10, min: seeLimit.min - 10 });
  };

  // Method when selecting only one row
  const handleSelectRow = (value: any) => {
    controlDataStatus(value === selectedRow ? {} : value, SelectedRow);
  };

  //Before component mounts

  useEffect(() => {
    if (tableState) {
 
      const { dataStatus, seeLimit, multipleSet, multipleSelectedItems } =
        tableState as TableState;
      setDataStatus(dataStatus);
      setLimit(seeLimit);
      setMultipleSet(multipleSet);
      setMultipleItemsSelected(multipleSelectedItems);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Apply the state after leaving

  useEffect(() => {
    if (setTableState) {
      setTableState({
        dataStatus,
        seeLimit,
        multipleSet,
        multipleSelectedItems,
      });
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStatus, seeLimit, multipleSet, multipleSelectedItems]);

  // When filtering
  useEffect(() => {
    if (filterWord === "") controlDataStatus(data ? data : [], DataFiltered);
    else {
      let filter = (data as any[]).filter((value) => {
        let found: boolean = false;
        for (const key in value) {
          if (headers.find((v) => v.valueProp === key)) {
            found = (value[key] as string)
              .toLowerCase()
              .includes(filterWord.toLowerCase());
            if (found) break;
          }
        }
        return found;
      });
      controlDataStatus(filter, DataFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterWord]);

  // When selected row change
  useEffect(() => {
    if (onSelect) {
      if (Object.keys(selectedRow).length === 0) {
        onSelect(null);
      } else onSelect(selectedRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  // Watch the mimits
  useEffect(() => {
    if (seeLimit.max > dataFiltered.length - 1 + 10) {
      let newMax = getDozen(dataFiltered.length - 1);
      setLimit({ max: newMax - 1, min: newMax - 11 });
    }
    if (!(Object.keys(selectedRow).length === 0)) {
      if (!_hooks._includes(dataFiltered, selectedRow))
        controlDataStatus({}, SelectedRow);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFiltered]);

  useEffect(() => {

    if (multipleSet === false) setMultipleItemsSelected([])

     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[multipleSet])  

  const setFilterWord = (value: string) => {
    controlDataStatus(value, FilterWord);
  };

  return {
    filterWord,
    setFilterWord,
    dataFiltered,
    selectedRow,
    handleSelectRow,
    seeLimit,
    increaseLimit,
    decreaseLimit,
    setMultipleSet,
    multipleSet,
    addNewItemToMultiple,
    multipleSelectedItems,
  };
};

export default useTable;
