import { css } from "styled-components";

export const FONTS = {
  navbarFont: css`
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #a4a6b3;
  `,
  topbarHeadFont: css`
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.3px;
  `,
  usernameFont: css`
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.2px;
    color: #252733;
  `,
  tableheaderStyle: css`
    //   font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #464f60;
  `,
  tablerowStyle: css`
    // font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #171c26;
  `,
  inputplaceHolder: css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #666666;
  `,
  labalform: css`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;
    text-transform: uppercase;

    color: #666666;
  `,
  selectdSelectCmp: css`
    // font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    text-align: center;
    text-transform: uppercase;
    color: black;
  `,
  sensorTitleBox: css`
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #252733;
  `,
  metricTitle: css`
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.2px;
    color: #252733;
  `,
  tablefooterFont: css`
   // font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #687182;
  `,
   selectionListData: css`
   font-family: "Mulish";
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 24px;
   text-align: center;
   letter-spacing: 0.4px;
   color:#687182;
 `
};
