import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UserFetchedProps } from "../../../../resources/utils/interfaces/CommonInterfaces";
import { AppContext } from "../../../../context/AppContext";

interface UseAdminUsersProps {
  userList: UserFetchedProps[];
  loading: boolean;
  setUserSelected: Dispatch<SetStateAction<UserFetchedProps | null>>;
  addNewUser: (callback?: Function) => void;
  userSelected: UserFetchedProps | null;
  modalStatus: ModalsSections;
  setDeleteChangeUser: (vars: ActModal, value: boolean) => void;
}

interface ModalsSections {
  editUser: boolean;
  deleteUser: boolean;
}

export enum ActModal {
  DeleteUser = "deleteUser",
  EditUser = "editUser",
}

const useUsersAdmin = (): UseAdminUsersProps => {
  const [userList, setUserList] = useState<UserFetchedProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [operationCount, setOperationCount] = useState<number>(0);
  const [modalStatus, setModalStatus] = useState<ModalsSections>({
    editUser: false,
    deleteUser: false,
  });
  const [userSelected, setUserSelected] = useState<UserFetchedProps | null>(
    null
  );
  const { userLogged } = AppContext();
  const {API_CALLER} = userLogged
 

  const setDeleteChangeUser = (vars: ActModal, value: boolean) => {
    setModalStatus({ ...modalStatus, [vars]: value });
  };

  const addNewUser = (callback?: Function): void => {
    if (callback) callback();
    setLoading(true);
    setOperationCount(operationCount + 1);
  };

  useEffect(() => {
    (async () => {
      const listUsers = await (await API_CALLER.get(userLogged.adminPrivileges?.callLink as string)).data;
      setUserList(listUsers.users);
      setLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationCount]);
  useEffect(() => {
    console.log(userSelected);
  }, [userSelected]);
  return {
    userList,
    loading,

    addNewUser,
    setUserSelected,
    userSelected,
    setDeleteChangeUser,
    modalStatus,
  };
};

export default useUsersAdmin;
