import { LoginActionProps, LoginActionType, LoginStateProps } from "./EnumsInterfaces";


const reducer = (state: LoginStateProps, action: LoginActionProps) : LoginStateProps=> {
    const {ChangeLoginVariable, SubmitLogin, ErrorLogin, SetLoadingOff} = LoginActionType
    switch (action.type) {
        case ChangeLoginVariable: {
            if (action.playload) {
                return {...state, loginCredential: {...state.loginCredential, [action.playload.variable] : action.playload.value}}
            }
            else {
                console.error("Playload must exsits")
                return state
            }
        }
        case SubmitLogin:  return {...state, submitting: true, success: true, loading: true};
        case ErrorLogin:  return {...state, submitting: false, success: false};
        case SetLoadingOff: return {...state, loading: false}
        
        default: 
            return state
    }
}

export default reducer;