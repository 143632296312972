

interface PrivateKeyProps {
    customer: string;
    privateKeyHex: string;
    readyToConnect: boolean
}


class PrivateKey implements PrivateKeyProps {
    public customer: string;
    public privateKeyHex: string;
    public readyToConnect: boolean;

    constructor({customer, privateKeyHex, readyToConnect} : PrivateKeyProps) {
        this.customer = customer;
        this.privateKeyHex = privateKeyHex.slice(0, 30) + '...';
        this.readyToConnect = readyToConnect;
    }
}


export default PrivateKey