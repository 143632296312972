import React from "react";
import { FONTS } from "../../../resources/utils/styles/FontStyles";
import { NoMatchContainer, Header, BoxReasons, Text } from "./NoMatchSC";

const NoMatch = () => {
  return (
    <NoMatchContainer>
      <BoxReasons>
        <Header theme={{ font: FONTS.sensorTitleBox }}>
          This route is not accessbile. This might be because one of the
          following reasons:
        </Header>

        <Text theme={{ font: FONTS.usernameFont }}>
          - This route doesn't exsits.
        </Text>
        <Text theme={{ font: FONTS.usernameFont }}>
          - You don't have the permission to visit this page.
        </Text>
        <Text theme={{ font: FONTS.usernameFont }}>
          - Page is under maintenance.
        </Text>
      
      </BoxReasons>
    </NoMatchContainer>
  );
};

export default NoMatch;
