import SDataGroup from "../classes/SDataGroup";
import { CallStatus, DataStatus } from "../interfaces/CommonInterfaces";

/** 
export const defaultLatestData: SensorData = {
    Mac: '',Name: 'RODE843',Temperature: '23', Humidity: '44', Movement: '110', Time: new Date()
} **/

export const defaultDataStatus : DataStatus = {
    data: new SDataGroup(),
    graphData: []
}

export const defaultCallStatus : CallStatus = {
    loading: true,
    readyToCall: false
}