import React from 'react'
import { SingleRowSensorData } from '../../../../resources/utils/interfaces/CommonInterfaces'
import { TableState } from '../../../components/table/tableUtils/TypesEnums.';
import { Container, Header, RouteTables, TableWrapper } from './DayDataSC'
import {ArrowBack} from '@mui/icons-material';
import { Link, useOutletContext } from 'react-router-dom';
import { DayDataOutletProps } from '../../../../resources/utils/interfaces/OutletRelatedInterfaces';
import { FONTS } from '../../../../resources/utils/styles/FontStyles';
import { singleDataLabels } from '../../../components/table/tableUtils/LabelVars';
import Table from '../../../components/table/Table';


export interface SingleDataProps {
    singleRows: SingleRowSensorData[];
    tableState: TableState;
    setTableStates: (stateNumber: number) => (value: any) => void;
    selectedRows: SingleRowSensorData[];
    sensorId: string;
    day: string;
} 

const SingleData = () => {
   const contextOutlet : DayDataOutletProps = useOutletContext();
   const {singleRows, tableState, setTableStates, selectedRows, sensorId, day} = contextOutlet.singleDataProps;
  return (
    <Container>
         <RouteTables >
          <Link to="../" children={<ArrowBack className='arrow-back--icon' />} />
          <Header
            theme={{font: FONTS.tableheaderStyle}}
          >
          {sensorId} | {day}
          </Header>
         </RouteTables>
         <TableWrapper>
      <Table 
        headers={singleDataLabels}
        data={singleRows}
        tableState={tableState}
        setTableState={setTableStates(3)}
        rowsApplied={selectedRows}
        multiple
        noSingleSelect
      />
      </TableWrapper>
    </Container>
  )
}

export default SingleData