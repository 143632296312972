
export enum ButtonType {
    Submit = "submit",
    Button = "button" 
}

export enum MainRoutePaths {
    HOME = '/',
    USERS = '/users',
    USERPAGE = '/userPage',
    DATA_LIST = '/dataList',
    REPORTS = '/reports',
    MARKETPLACE = '/marketplace',
    PRIVATE_KEYS = '/privateKeys'
}

export enum SubRoutePaths {
    GRAPH_VIEW  = "graphview/sensor",
    ALL_DATA = "",
    DATA_EXPORT = "dataexport"

}

export enum UserRoles {
    Admin = "Admin",
    User = "User",
    Partner = "Partner",
    Admin_Customer = "Admin_Customer",
    Guest = "Guest"
}

export enum DataKey {
    Temperature= "Temperature",
    Movement = "Movement",
    Humidity = "Humidity"
}


export enum ButtonIcons {
    DeleteIcons = "deleteIcon",
    EditIcon = "editIcon",
    ExportIcon = "exportIcon",
    AddIcon = "addIcon",
    UpgradeIcon = "upgradeIcon",
    UploadIcon = "uploadIcon",
    FindReplace = "findReplace"
}

export enum InputIcons {
    UserIcon = "userIcon",
    PasswordIcon = "passwordIcon",
    PhoneIcon ="phoneIcon",
    ContactMail = "contactMail",
    BusinessIcon = "businessIcon"
}

export enum BUserInfoState {
    Name = "name",
    PhoneNumber = "phoneNumber",
    EmailAddress = "emailAddress"
}

export enum PUserInfoState {
    OldPassword = "oldPassword",
    NewPassword = "newPassword",
    RepeatNewPassword = "repeatNewPassword"
}


