import styled from "styled-components";
import { FONTS } from "../../../../resources/utils/styles/FontStyles";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ANIMATIONS from "../../../../resources/utils/styles/OtherCss";

export const Header = styled.h1`
  ${(props) => props.theme.font}
  font-size: 16px;
  text-transform: uppercase;
  //  color: ${({ chosen }: any) => chosen && "#fff"};
`;

export const SensorTitleBox = styled.div`
  width: 70%;
  min-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const SensorTitle = styled.h1`
  ${FONTS.sensorTitleBox}
  font-size: 22px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GraphViewContainer: any = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  //left: 50%;
  left: ${({ show }: any) => (show ? "0" : " 50%")};
  transition: all 400ms ease-out;

  //display: none;
  display: ${({ show }: any) => (show ? "flex" : "none")};
  opacity: ${({ show }: any) => (show ? "1" : "0.7")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //
  ${ANIMATIONS.graphAmimation}
`;

export const GraphSecondrayContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  /** 
  @keyframes example {
    0% {
      left: 100px;
      opacity: 0.4;
    }
    100% {
      left: 0px;
      opacity: 1;
    }
  } **/

`;

export const MetricRow = styled.div`
  width: 200px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MetricIcon = styled.img`
  width: 20px;
  height: 30px;
  margin-right: 18px;
`;

export const BackIcon = styled(ArrowCircleLeftIcon)`
  background: white;
  color: #1151f5;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(1.5);
  transition: all 500ms ease-in-out;
  &:hover {
    transition: all 500ms ease-out;
    background: #1151f5;
    color: white;
    opacity: 0.8;
    transform: scale(1.7) translateY(-2px);
  }
`;

export const BackIconCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  background: #1151f5;
  width: 35px;
  height: 35px;
  transition: all 200ms ease-in-out;

  &:hover {
    opacity: 0.8;
    transform: scale(1.1) translateY(-2px);
  }
  & > .--icon {
    color: white;
    transform: scale(1.1);
  }
`;
