import { DataKey } from "../enums/CommonEnums";


export interface SensorDataProps{
  Humidity: string;
  Mac: string;
  Temperature: string;
  Movement: string;
  Name: string;
  Time: Date;
  showFloat?: (dataKey: DataKey) => number 
}

class SensorData implements SensorDataProps {
    public Humidity: string;
    public Mac: string;
    public Temperature: string;
    public Movement: string;
    public Name: string;
    public Time: Date;

    /** 
    constructor(Humidity: string, Mac: string, Temperature: string, Movement: string, Name: string,  Time: Date) {
        this.Humidity = Humidity;
        this.Mac = Mac;
        this.Temperature = Temperature;
        this.Movement = Movement;
        this.Name = Name;
        this.Time = Time
    } **/ 
    constructor({Humidity, Mac, Temperature, Movement, Name,  Time}: SensorDataProps) {
      this.Humidity = Humidity;
      this.Mac = Mac;
      this.Temperature = Temperature;
      this.Movement = Movement;
      this.Name = Name;
      this.Time = Time
  }
     showFloat = (dataKey: DataKey) : number => {
       return parseFloat(this[dataKey]);
    //   return 11;
    } 


}

export default SensorData;
