import { Dispatch, SetStateAction } from "react";
import { useOutletContext } from "react-router-dom";
import { UserFetchedProps } from "../../../../resources/utils/interfaces/CommonInterfaces"
import { UsersAVOutletProps } from "../../../../resources/utils/interfaces/OutletRelatedInterfaces"
import Table from "../../../components/table/Table";
import { userListLabels } from "../../../components/table/tableUtils/LabelVars"
import { UsersListContainer } from "./UsersListSC";


export interface UsersListProps {
  setUserSelected: Dispatch<SetStateAction<UserFetchedProps | null>>
  userList: UserFetchedProps[]
}

const UsersList = () => {
  const {usersListProps} : UsersAVOutletProps= useOutletContext()
  const {setUserSelected, userList
  
  } = usersListProps
  return (
    <UsersListContainer>
        <Table 
          headers={userListLabels}
          onSelect={(value) => setUserSelected(value)}
          data={userList}
        />      
    </UsersListContainer>
  )
}

export default UsersList