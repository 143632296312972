import { GraphContainer } from "./GraphSC"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DataKey } from "../../../../resources/utils/enums/CommonEnums";
import useGraph from "./UseGraph";
import SensorData from "../../../../resources/utils/classes/SensorData";


export interface GraphProps {
    data : SensorData[];
    dataKey: DataKey;
}

const Graph = (props: GraphProps) => {
  const {dataGraph} = useGraph(props);
  useGraph(props);
  return (
    <GraphContainer>
             <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={dataGraph}
         // data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis  dataKey="time"  tick={{fontSize: '10px'}} />
          <YAxis tick={{fontSize: '10px'}} />
          <Tooltip />
          <Area type="monotone" dataKey={props.dataKey}  stroke="#8884d8" fill="#1151F5" />
        </AreaChart>
      </ResponsiveContainer>
    </GraphContainer>
  )
}

export default Graph