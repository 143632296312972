import SelectedRows from "../../../../resources/utils/classes/SelectedRows";
import TablesStates from "../../../../resources/utils/classes/TableStates";
import { DMStateProps } from "./ReducerIntsEnums";



const DMStateDefaultValues : DMStateProps = {
    tablesStates: new TablesStates(),
    selectedData: new SelectedRows(),

}

export default DMStateDefaultValues;