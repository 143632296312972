import styled, { css } from "styled-components";

export const ButtonContainer: any = styled.button`

    display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  outline: none;
  transition: all 500ms ease-out;
  opacity: ${({ disabled }: any) => (disabled ? "0.6" : "1")};
  &:hover {
    //  opacity: 0.8;
    opacity: ${({ disabled }: any) => !disabled && "0.8"};
    // transform: translateY(-2px);
    transform: ${({ disabled }: any) => !disabled && "translateY(-2px)"};
  }
  & > .icon--button {
    color: #fff;
  }
  ${(props) => props.theme.size}
  ${(props) => props.theme.color}
`;

// sizes
export const loginButtonSize = css`
  width: 100%;
  height: 40px;
  & > h6 {
    font-size: 16px;
    font-weight: 500;
  }
`;
// colors
export const loginButtonColor = css`
  background: #1151f5;
  color: #ffff;
`;

export const addUserSize = css`
  width: 180px;
  height: 32px;
  font-size: 18px;
  & > h6 {
    font-weight: 400;
  }
  & > .icon--button {
    width: 20px;
    height: 20px;
  }
`;

export const mediumButtonSize = css`
  width: 165px;
  height: 32px;

  & > .icon--button {
   transform: scale(0.8);
  }
  & > h6 {
    font-weight: 400;
    font-size: 12px;

  }
 
`;

export const consistentSize = css`
  padding: 10px 15px;
  
  & > .icon--button {
   transform: scale(0.8);
  }
  & > h6 {
    font-weight: 400;
    font-size: 12px;

  }
`;

export const smallButtonSize = css`
  width: 120px;
  height: 28px;

  & > .icon--button {
   transform: scale(0.8);
  }
  & > h6 {
    font-weight: 300;
    font-size: 10px;

  }
 
`;



