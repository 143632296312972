import styled from "styled-components";

export const FieldWrapper = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #1151f5;
  //margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > .input--icon {
    width: 30px;
  height: 20px;
  margin-left: 10px;
  color: #666666;
  }
  //  padding: 0 10px;
/** 
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
     box-shadow: none;
    -webkit-box-shadow: 0 0 0 30px rgba(0,0,0,0) inset !important;
  } **/
`;

export const IconDisplay = styled.img`
  width: 30px;
  height: 20px;
  margin-left: 10px;
`;

export const InputField = styled.input`
  background: transparent!important;
  width: 85%;
  height: 95%;
  border: none;
  outline: none;
  font-size: 15px;
  ::placeholder {
    color: #666666;
    font-size: 14px;
    line-height: 20px;
  }
  //opacity: 0;
`;
