import {
  createContext,
  ReactNode,
  useContext,
} from "react";
import LoadingPage from "../pages/loadingPage/LoadingPage";
import UserLocal from "../resources/utils/classes/UserLocal";
import { MainRoutePaths } from "../resources/utils/enums/CommonEnums";
import {  NavbarDataProps } from "../resources/utils/interfaces/CommonInterfaces";
import useAppContext from "./UseAppContext";

export interface AppContextProps  {
  isLoggedIn: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  currentPage: NavbarDataProps;
  checkPageViewRight: (page? : MainRoutePaths) => boolean;
  userLogged: UserLocal;
  updateUserStatus: (user: UserLocal) => void
} 

interface AppProviderProps {
  children: ReactNode | ReactNode[];
}

const AppContextCreated = createContext({
} as AppContextProps);
const AppProvider = ({ children }: AppProviderProps) => {
  const {passedToValues, loading} = useAppContext();
  return (
    <AppContextCreated.Provider
      value={passedToValues}
    >
      {loading ? <LoadingPage /> : children}
    </AppContextCreated.Provider>
  );
};
const AppContext = () => useContext(AppContextCreated);

export { AppContext, AppProvider };
