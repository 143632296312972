import React from 'react'
import { Link, Navigate, Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {  SensorDayRow } from '../../../../resources/utils/interfaces/CommonInterfaces'
import { AllDataOutletProps, DayDataOutletProps } from '../../../../resources/utils/interfaces/OutletRelatedInterfaces';
import Table from '../../../components/table/Table';
import { Container, Header, RouteTables, TableWrapper } from './DayDataSC';
import { dayListLabels } from '../../../components/table/tableUtils/LabelVars'
import {ArrowBack} from '@mui/icons-material';
import { FONTS } from '../../../../resources/utils/styles/FontStyles';
import TablesStates from '../../../../resources/utils/classes/TableStates';
import SelectedRows from '../../../../resources/utils/classes/SelectedRows';

export interface DayDataProps {
  dayRows: SensorDayRow[];
  tablesStates: TablesStates;
  setTableStates: (stateNumber: number) => (value: any) => void;
  selectedRows: SelectedRows

}

const DayData = () => {
  const outletContext : AllDataOutletProps = useOutletContext();
  const params = useParams();
  const {dayRows, tablesStates, setTableStates, selectedRows} = outletContext.dayDayProps;
  const navigate = useNavigate();

  if (params.day){
    let singleRows = dayRows.find((v) => v.day === params.day)?.allDayData
    if (singleRows)
    return (<Outlet 
      context={{singleDataProps: {
        singleRows: singleRows ?? [],
        tableState: tablesStates.singleInfoTable,
        setTableStates,
        selectedRows: selectedRows.signleRowData,
        sensorId: params.sensorId,
        day: params.day
      }}as DayDataOutletProps}
    />);
     return <Navigate to="../" />
  };
  return (
    <Container>
         <RouteTables >
          <Link to="../" children={<ArrowBack className='arrow-back--icon' />} />
          <Header
            theme={{font: FONTS.tableheaderStyle}}
          >
          {params.sensorId} 
          </Header>
         </RouteTables>
         <TableWrapper>
      <Table 
        headers={dayListLabels}
        data={dayRows}
        tableState={tablesStates.dayTable}
        setTableState={setTableStates(2)}
        rowsApplied={selectedRows.daySelected}
        onRowOpen={(val) => navigate(`day&${val.day}`)}
        multiple
      />
      </TableWrapper>
    </Container>
  )
}

export default DayData