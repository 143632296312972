import UserLocal from "../../resources/utils/classes/UserLocal";
import { MainRoutePaths } from "../../resources/utils/enums/CommonEnums";
import { ContextStateProps } from "./reducerIntsEnums";



const contextDefaultValues : ContextStateProps = {
    isLoggedIn: false,
    loading: true,
    currentPage: {
        page: 'Home',
        path: MainRoutePaths.HOME
    },
    userLogged: new UserLocal('s')
}

export default contextDefaultValues;