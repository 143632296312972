import styled from "styled-components";
import { FONTS } from "../../../../resources/utils/styles/FontStyles";

export const NewPrivateKeyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PrivateBox = styled.div`
  width: 400px;
  min-height: 350px;
  background: #fff;
  border: 1px solid #f9fafc;
  position: relative;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  .select--box {
    width: 80%;
  }
`;

export const HeaderUpload = styled.h1`
  ${(props) => props.theme.font}
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  transition: all 300ms ease-out;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    .cloud--icon {
      opacity: 0.8;
    }
  }
`;

export const PivateKeyText = styled.p`
  ${FONTS.tablefooterFont}
  font-weight: 500;
  height: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  .close--icon {
    transition: all 300ms ease-out;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  .--btn {
    width: 90px;
    & > h6 {
      font-size: 12px;
    }
  }
`;
