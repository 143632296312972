import styled from "styled-components";


export let Header = styled.h1`
  ${(props) => props.theme.font}
  text-transform: none;
  font-size: 16.5px;
`;

export let Text = styled.p`
  ${(props) => props.theme.font}
  text-transform: none;
  font-size: 14px;
`;




export const NoMatchContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxReasons = styled.div`
    width: 650px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    & > h1 {
        margin-bottom: 25px;
    }
    
`;