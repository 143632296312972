import { useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import SDataGroup from "../../../resources/utils/classes/SDataGroup";
import { defaultCallStatus } from "../../../resources/utils/data/defaultStateVariables";
import {
  CallStatus,
  SDataGroupObj,
  SensorDayRow,
  SensorRow,
} from "../../../resources/utils/interfaces/CommonInterfaces";

interface UseDataListProps {
  sensorRows: SensorRow[];
  loading: boolean;
  getDayData: (sensorId: string, day?: string ) => SensorDayRow[] | undefined 
}

const useDatalist = (): UseDataListProps => {
  const [allData, setAllData] = useState<SDataGroup>(new SDataGroup());
  const { userLogged } = AppContext();
  const { API_CALLER } = userLogged;
  const [callStatus, setCallStatus] = useState<CallStatus>(defaultCallStatus);

  const getDayData = (sensorId: string, day?: string): SensorDayRow[] | undefined  => {
    let returnedArray = allData.daySensorData.get(sensorId);
    return returnedArray;
  };

  useEffect(() => {
    API_CALLER.get("ssh_data/")
      .then((res) => {
        setCallStatus({ ...callStatus, readyToCall: true });
      })
      .catch((error) => {
        console.log(error);
        //  setCallStatus({ ...callStatus, readyToCall: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callStatus.readyToCall) {
      API_CALLER.get(`ssh_data/getdata&noSort=yes`)
        .then((res) => {
          setAllData(new SDataGroup(res.data.data as SDataGroupObj));
          setCallStatus({ ...callStatus, loading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => {
      // setDataStatus({ ...dataStatus, allData: {} });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callStatus.readyToCall]);

  /** 
  useEffect(() =>{
    console.log(allData);
  },[allData]) **/
  return {
    sensorRows: allData.setRows(),
    loading: callStatus.loading,
    getDayData,
  };
};

export default useDatalist;
