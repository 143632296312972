import {  Dispatch, SetStateAction, useEffect, useReducer, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import SelectedRows from "../../../resources/utils/classes/SelectedRows";
import TablesStates from "../../../resources/utils/classes/TableStates";
//import { SelectedRows, TablesStates } from "../../../resources/utils/interfaces/CommonInterfaces";
import DMStateDefaultValues from "./reducerUtils/ReducerDefault";
import reducer from "./reducerUtils/ReducerFunction";
import { DMActionEnum } from "./reducerUtils/ReducerIntsEnums";


interface DataListStatesProps {
    tablesStates: TablesStates
  //  setTableState: Dispatch<SetStateAction<TableState |  null>>;
  setTableStates: (stateNumber: number) => (value: any) => void;
  deleteFullySelected: (num: number) => (value: any) => void
  updateSelections: () => void;
  nameTheCSVFile: () => string;
  setDownloadCounter: Dispatch<SetStateAction<number>>
  selectedRows: SelectedRows
}

export const useDataMemory = () : DataListStatesProps => {
 //   const [tableState, setTableState] = useState<TableState |  null>(null);
    const [state, dispatch] = useReducer(reducer, DMStateDefaultValues);
    const [downloadCounter, setDownloadCounter] = useState<number>(0);
    const {userLogged} = AppContext();
    const {tablesStates, selectedData} = state
    const setTableStates = (stateNumber: number) => {
        return (value: any )=> {
            dispatch({type: DMActionEnum.SetTableState, playload: {value, variable: stateNumber}})
        }
    }

    const deleteFullySelected = (num: number) => {
        return (value : any) => {
            dispatch({type: DMActionEnum.DeleteFullySelected, playload: {value, variable: num}})
        }
    }

    const reset = () => dispatch({type: DMActionEnum.ResetAfterExport});
    const nameTheCSVFile = () => userLogged.name +'-'+new Date().toLocaleString()
    useEffect(() => {
        reset();
    },[downloadCounter])
    /** 
    useEffect(() => {
        console.log(selectedData);
    },[selectedData]) **/
 
 
    const updateSelections = () => dispatch({type: DMActionEnum.UpdateSelections});

    return {tablesStates, setDownloadCounter,setTableStates, updateSelections, selectedRows: selectedData, deleteFullySelected, nameTheCSVFile}
}


