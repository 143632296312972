import React from 'react'
import Button from '../../../../resources/customs/button/Button'
import { smallButtonSize } from '../../../../resources/customs/button/ButtonSC'
import Loader from '../../../../resources/customs/loader/Loader'
import { UserFetchedProps } from '../../../../resources/utils/interfaces/CommonInterfaces'
import { ConfirmationSection, DeleteUserBox, DeleteUserContainer, Header, LoaderSection } from './DeleteUserSC'
import useDeleteUser from './UseDeleteUser'

export interface DeleteUserLocal {
    user: UserFetchedProps;
    closeModal: () => void
    callback: () => void
}

const DeleteUser = (props: DeleteUserLocal) => {
    const {status, deleteConfirm} = useDeleteUser(props);
    return (
        <DeleteUserContainer>
            <DeleteUserBox>
                <Header>
                    Are you sure that you would like to delete this user ?
                </Header>
                <ConfirmationSection>
                    <Button
                        buttonSize={smallButtonSize}
                        onClick= {() => deleteConfirm()}
                    >
                        YES
                    </Button>
                    <Button
                        buttonSize={smallButtonSize}
                        onClick={() => props.closeModal()}
                    >
                        NO
                    </Button>
                </ConfirmationSection>
                {status.submit && 
                <LoaderSection>
                    <Loader scale={0.4} />
                </LoaderSection> }
            </DeleteUserBox>
        </DeleteUserContainer>
    )
}

export default DeleteUser