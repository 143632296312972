import { FlattenSimpleInterpolation } from "styled-components";
import { ButtonIcons, ButtonType } from "../../utils/enums/CommonEnums";
import IconsJSX from "./ButtonIcons";
import { ButtonContainer, loginButtonColor, loginButtonSize } from "./ButtonSC";

export interface ButtonProps {
  children: string;
  buttonSize?: FlattenSimpleInterpolation;
  ButtonColor?: FlattenSimpleInterpolation;
  type?: ButtonType;
  icon?: ButtonIcons
  className?: string;
  onClick?: Function;
  disabled?: boolean
}

const Button = (props: ButtonProps) => {
  const { children, buttonSize, ButtonColor, onClick, className, type, icon, disabled } = props
  const size = buttonSize ? buttonSize : loginButtonSize;
  const color = ButtonColor ? ButtonColor : loginButtonColor;

  return (
    <ButtonContainer
      type={type ? type : ButtonType.Button}
      className={className ? className : 'nonclass'}
      theme={{ size, color }}
      onClick={() => (onClick ? onClick() : null)}
      disabled={disabled ? disabled : false}
    >
      {icon && IconsJSX[icon]()}
      <h6>{children}</h6>
    </ButtonContainer>
  );
};

export default Button;
