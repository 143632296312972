import Input from "../../../../resources/customs/input/Input";
import {
  AddUserContainer,
  AddUserWrapper,
  ButtonSection,
  Header,
  Inputs,
  RadioLabel,
  RadioLine,
  RadioSection,
  LoadErrorSection,
  ErrorContainer,
} from "./AddUserSC";
import { FONTS } from "../../../../resources/utils/styles/FontStyles";
import Radio from "../../../../resources/customs/radio/Radio";
import Button from "../../../../resources/customs/button/Button";
import { addUserSize } from "../../../../resources/customs/button/ButtonSC";
import useAddUser from "./UseAddUser";
import {
  ButtonType,
  InputIcons,
  UserRoles,
} from "../../../../resources/utils/enums/CommonEnums";
import Loader from "../../../../resources/customs/loader/Loader";
import { memo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { UsersAVOutletProps } from "../../../../resources/utils/interfaces/OutletRelatedInterfaces";

export interface AddUsersProps {
  addNewUser: (callback: Function) => void;
}

const AddUser = () => {
  const outletContext: UsersAVOutletProps = useOutletContext();
  const navigate = useNavigate();
  const { addNewUser } = outletContext.addUsersProps;
  const {
    formValues,
    controlForm,
    handleRoleComp,
    submitForm,
    status,
    errors,
    privileges,
  } = useAddUser({ addNewUser });
  const { email, emailRepeat, password, passwordRepeat, role, name, company } =
    formValues;
  console.log("Reached here");
  return (
    <AddUserContainer>
      <AddUserWrapper onSubmit={submitForm}>
        <Inputs>
          <Input
            className="input--form"
            icon={InputIcons.UserIcon}
            value={name}
            onChange={(e) => controlForm(e)}
            type="text"
            placeholder="FULL NAME"
            name="name"
          />
          <Input
            className="input--form"
            icon={InputIcons.UserIcon}
            value={email}
            onChange={(e) => controlForm(e)}
            type="text"
            placeholder="EMAIL"
            name="email"
          />
          <Input
            className="input--form"
            icon={InputIcons.UserIcon}
            value={emailRepeat}
            onChange={(e) => controlForm(e)}
            type="text"
            placeholder="REPEAT EMAIL"
            name="emailRepeat"
          />
          <Input
            className="input--form"
            icon={InputIcons.PasswordIcon}
            value={password}
            onChange={(e) => controlForm(e)}
            type="text"
            placeholder="PASSWORD"
            name="password"
          />
          <Input
            className="input--form"
            icon={InputIcons.PasswordIcon}
            value={passwordRepeat}
            onChange={(e) => controlForm(e)}
            type="text"
            placeholder="REPEAT PASSWORD"
            name="passwordRepeat"
          />
          {/** 
        <Select
            onChange={(v) => handleRoleComp(v, 'company')}
        />   */}
          <Input
            disabled={!privileges.fullControl}
            className="input--form"
            icon={InputIcons.BusinessIcon}
            value={privileges.fullControl ? company : privileges.company}
            onChange={(e) => controlForm(e)}
            type="text"
            placeholder="Company"
            name="company"
          />
          <Header theme={{ font: FONTS.labalform }} className="--labelrole">
            Role:
          </Header>
          <RadioSection>
            <RadioLine>
              <Radio
                value={UserRoles.User}
                selectedValue={role}
                onClick={(v) => handleRoleComp(v, "role")}
              />
              <RadioLabel>USER</RadioLabel>
            </RadioLine>
            <RadioLine>
              <Radio
                value={UserRoles.Partner}
                selectedValue={role}
                onClick={(v) => handleRoleComp(v, "role")}
              />
              <RadioLabel>PARTNER</RadioLabel>
            </RadioLine>
            {privileges.fullControl && (
              <RadioLine>
                <Radio
                  value={UserRoles.Admin}
                  selectedValue={role}
                  onClick={(v) => handleRoleComp(v, "role")}
                />
                <RadioLabel>ADMIN</RadioLabel>
              </RadioLine>
            )}
            <RadioLine>
              <Radio
                value={UserRoles.Admin_Customer}
                selectedValue={role}
                onClick={(v) => handleRoleComp(v, "role")}
              />
              <RadioLabel>ADMIN CUSTOMER</RadioLabel>
            </RadioLine>
          </RadioSection>
        </Inputs>
        <ButtonSection>
          <Button buttonSize={addUserSize} type={ButtonType.Submit}>
            CONFIRM
          </Button>
          <Button buttonSize={addUserSize} onClick={() => navigate("../")}>
            BACK
          </Button>
        </ButtonSection>
        <LoadErrorSection>
          {status.loading && status.submitted ? (
            <Loader scale={0.7} />
          ) : status.success ? (
            <ErrorContainer>
              {" "}
              <p>USER ADDED SUCCESSFULLY</p>{" "}
            </ErrorContainer>
          ) : (
            status.success === false && (
              <ErrorContainer>
                <p> {errors[0]} </p>
              </ErrorContainer>
            )
          )}
        </LoadErrorSection>
      </AddUserWrapper>
    </AddUserContainer>
  );
};

export default memo(AddUser);
