import {
  ErrorBox,
  FieldSection,
  LoaderErrorBox,
  LoginBox,
  LoginPageContainer,
  LoginParentBox,
  LogoDisplay,
  Text,
} from "./LoginPageSC";
import CortexLogo from "../../resources/images/logos/cortexLogo.svg";

import Button from "../../resources/customs/button/Button";
import useLoginPage from "./UseLoginPage";
import { StateVariables } from "./reducerUtils/EnumsInterfaces";
import { ButtonType, InputIcons } from "../../resources/utils/enums/CommonEnums";
import Loader from "../../resources/customs/loader/Loader";
import Input from "../../resources/customs/input/Input";

const LoginPage = () => {
  const {
    username,
    password,
    handleInputChange,
    loginAction,
    loading,
    submitting,
    success,
  } = useLoginPage();
  return (
    <LoginPageContainer>
      <LoginParentBox>
        <LoginBox>
          <LogoDisplay src={CortexLogo} alt="Cortex IoT logo" />
          <FieldSection onSubmit={loginAction}>
            <Input 
              icon={InputIcons.UserIcon}
              type="text"
              placeholder="EMAIL"
              name={StateVariables.Username}
              value={username}
              onChange={(e) => handleInputChange(e)}
            />
              <Input 
              icon={InputIcons.PasswordIcon}
              type="password"
              placeholder="PASSWORD"
              name={StateVariables.Password}
              value={password}
              onChange={(e) => handleInputChange(e)}
            />
            <Button className="button--cls" type={ButtonType.Submit}>
              Login
            </Button>
          </FieldSection>
        </LoginBox>
        <LoaderErrorBox>
          {loading && submitting ? (
            <Loader />
          ) : (
            !success && (
              <ErrorBox>
                <Text>Credentials are not valid!</Text>
              </ErrorBox>
            )
          )}
        </LoaderErrorBox>
      </LoginParentBox>
    </LoginPageContainer>
  );
};

export default LoginPage;
