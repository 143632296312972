import React from "react";
import { FONTS } from "../../../resources/utils/styles/FontStyles";
import {
  PageHead,
  TopbarContainer,
  Header,
  UserBasicInfo,
  NotesBarSection,
  NotificationSection,
  UserProfile,
  LineHead,
} from "./TopbarSC";
import { Search, Notifications, Logout } from "@mui/icons-material";
import RandomUserIcon from "../../../resources/images/icons/userTopIcon.svg";
import { AppContext } from "../../../context/AppContext";
const Topbar = () => {
  const { currentPage, logout, userLogged} = AppContext();
  return (
    <TopbarContainer>
      <PageHead>
        <Header theme={{ font: FONTS.topbarHeadFont }}>
          {/** Hardcoded for now */}
          {currentPage.page.toUpperCase()}
        </Header>
      </PageHead>
      <NotesBarSection>
        <NotificationSection>
          <Logout onClick={() => logout()} className="note--icons" />
          <Search className="note--icons" />
          <Notifications className="note--icons" />
        </NotificationSection>
        <UserBasicInfo>
          <LineHead />
          <Header theme={{ font: FONTS.usernameFont }}>
            {/** Hardcoded for now */}
            {userLogged.name ? userLogged.name : "Hardcoded name"}
          </Header>
          <UserProfile src={userLogged.photoURL ?? RandomUserIcon} alt="Random icon" />
        </UserBasicInfo>
      </NotesBarSection>
    </TopbarContainer>
  );
};

export default Topbar;
