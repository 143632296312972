import styled from "styled-components";
import ANIMATIONS from "../../../../resources/utils/styles/OtherCss";


export const DataBlocksWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  position: absolute;
  //position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 130px;
  row-gap: 5px;
  gap: 8px;
  transition: all 400ms ease-out;
  ${ANIMATIONS.basicpageAnimation}
`;


