import Button from "../../../../resources/customs/button/Button";
import { addUserSize, mediumButtonSize } from "../../../../resources/customs/button/ButtonSC";
import Loader from "../../../../resources/customs/loader/Loader";
import useUsersAdmin, { ActModal } from "./UseUsersAdmin";
import { AddUserSection, UserChangeCnt, UsersAdminContainer, UsersAdminWrapper,UsersTable } from "./UsersSC";
import { ButtonIcons } from "../../../../resources/utils/enums/CommonEnums";
import DeleteUser from "../../../layouts/usersLayouts/deleteUser/DeleteUser";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UsersAVOutletProps } from "../../../../resources/utils/interfaces/OutletRelatedInterfaces";

const UsersAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userList, loading, addNewUser, setUserSelected, userSelected, modalStatus, setDeleteChangeUser } = useUsersAdmin();
  const { editUser, deleteUser } = modalStatus;

  return (
    <UsersAdminContainer>
      <UsersAdminWrapper>
      <UsersTable>
        {loading ? <Loader scale={1.1} /> : <Outlet context={{ usersListProps: { setUserSelected, userList }, addUsersProps: { addNewUser } } as UsersAVOutletProps} />
        }

        {((deleteUser || editUser) && userSelected) &&
          <UserChangeCnt>
            {deleteUser && <DeleteUser
              callback={addNewUser}
              user={userSelected}
              closeModal={() => setDeleteChangeUser(ActModal.DeleteUser, false)}
            />}
          </UserChangeCnt>}
      </UsersTable>
      <AddUserSection>
        <Button
          buttonSize={addUserSize}
          //onClick={() => setAddUserSection(true)}
          onClick={() => navigate('addUser')}
          disabled={location.pathname === '/users/addUser'}
        >
          ADD NEW USER
        </Button>
        <Button
          buttonSize={mediumButtonSize}
          disabled={userSelected === null}
          icon={ButtonIcons.DeleteIcons}
          onClick={() => setDeleteChangeUser(ActModal.DeleteUser, true)}
        >
          DELETE USER
        </Button>
        <Button
          buttonSize={mediumButtonSize}
          disabled={userSelected === null}
          icon={ButtonIcons.EditIcon}
        >
          EDIT USER
        </Button>
      </AddUserSection>
      </UsersAdminWrapper>
    </UsersAdminContainer>
  )
}

export default UsersAdmin