import { ChangeEventHandler } from "react";
import { InputIcons } from "../../utils/enums/CommonEnums";
import IconsInputJSX from "./InputIcons";
import { FieldWrapper, InputField } from "./InputSC";


export interface InputProps {
    icon?: InputIcons;
    type?: string;
    placeholder?: string;
    name?: string;
    value?: string;
    className?: string;
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>
}

const Input = ({icon, type, placeholder, name, value, onChange, className, disabled} : InputProps) => {
  return (
    <FieldWrapper
      className={className ? className : 'noclass'}
    >
      {icon &&  IconsInputJSX[icon]   } 
        <InputField   
             autoComplete="off"
             type={type ?? "text"}
             placeholder={placeholder ? placeholder : ''}
             name={name ? name : ''}
             value={value ? value : ''}
             onChange={onChange ? onChange : () => null}
             disabled={disabled ?? false}
        />
    </FieldWrapper>
  )
}

export default Input