import { auth } from "../../resources/firebase";
import UserLocal from "../../resources/utils/classes/UserLocal";
import {
  ContextActionProps,
  ContextStateProps,
  ContextActionEnum,
} from "./reducerIntsEnums";

const reducer = (
  state: ContextStateProps,
  action: ContextActionProps
): ContextStateProps => {
  const { LoginRegister, LogoutRegister, ChangeVariable, SetLoadingOff , UpdateToken} =
    ContextActionEnum;
  switch (action.type) {
    case ChangeVariable: {
      if (action.playload?.variable)
        return { ...state, [action.playload.variable]: action.playload.value };
      else return state;
    }
    case LoginRegister: {
      if (action.playload) {
        const val = action.playload.value;

        const userLogged = new UserLocal(val.accessToken,val.photoURL ,auth)
      
        return { ...state, isLoggedIn: true, userLogged };
      } else return state;
    }
    case LogoutRegister:
      return { ...state, isLoggedIn: false };
    case SetLoadingOff:
      return { ...state, loading: false };
    case UpdateToken: {
      const userLogged = action.playload?.value.user as UserLocal;

      return {...state, userLogged} 
    } 

    default:
      return state;
  }
};

export default reducer;
