import {
  DMActionEnum,
  DMActionProps,
  DMStateProps,
  DMVariables,
} from "./ReducerIntsEnums";
import TablesStates from "../../../../resources/utils/classes/TableStates";
import SelectedRows from "../../../../resources/utils/classes/SelectedRows";
import {
  SensorDayRow,
  SensorRow,
  SingleRowSensorData,
} from "../../../../resources/utils/interfaces/CommonInterfaces";

interface Refer {
  [other: number]: DMVariables;
}

let stateReferer: Refer = {
  1: DMVariables.AllSensorTable,
  2: DMVariables.DayTable,
  3: DMVariables.SingleInfoTable,
};

let selectReferer: Refer = {
  1: DMVariables.AllSensorSelected,
  2: DMVariables.DaySelected,
  3: DMVariables.SignleRowData,
};

const reducer = (state: DMStateProps, action: DMActionProps): DMStateProps => {
  switch (action.type) {
    case DMActionEnum.SetTableState: {
      if (action.playload && action.playload.variable) {
        const { value, variable } = action.playload;
        let perviousTableStates = new TablesStates(state.tablesStates);
        let newState = {
          ...state,
          tablesStates: new TablesStates({
            ...perviousTableStates,
            [stateReferer[variable as number]]: value,
          }),
        };
        return newState;
      } else return state;
    }
    // Long case
    case DMActionEnum.UpdateSelections: {
      let tablesStates = new TablesStates(state.tablesStates);
      let selectedData = new SelectedRows(state.selectedData);
      selectedData.updateItems(tablesStates);
      tablesStates.clearItems(selectedData);
      return {
        ...state,
        tablesStates,
        selectedData,
      };
    }
    case DMActionEnum.DeleteFullySelected: {
      let selectedData = new SelectedRows(state.selectedData);
      if (action.playload && action.playload.variable) {
        const { value, variable } = action.playload;
        let mi = selectReferer[variable as number];
        switch (mi) {
          case DMVariables.AllSensorSelected:
            selectedData.deleteAllSensor(value as SensorRow);
            break;
          case DMVariables.DaySelected:
            selectedData.deleteDayData(value as SensorDayRow);
            break;
          case DMVariables.SignleRowData:
            selectedData.deleteSingleData(value as SingleRowSensorData);
            break;
          default:
            break;
        }
      }
      return { ...state, selectedData };
    }
    case DMActionEnum.ResetAfterExport: {
      let selectedData = new SelectedRows(state.selectedData);
      let tablesStates = new TablesStates(state.tablesStates);
      tablesStates.reset();
      selectedData.reset();
      return { ...state, selectedData, tablesStates };
    }
    // Default case
    default:
      return state;
  }
};

export default reducer;
