import styled from "styled-components";



export const Label = styled.label`
  ${(props) => props.theme.font}
  font-size: 12px;
`; 

export const LabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
    
`;

export const InputLabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  .input--inside {
    height: 33px;
    & > input {
      font-size: 13px;
      ::placeholder {
        font-size: 13px;
      }
    }

}


  //background: red;
`;
