import styled from "styled-components";


export const LoadingPageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F2F4F8;
`;