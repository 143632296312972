import { useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import SDataGroup from "../../../resources/utils/classes/SDataGroup";
import {
  defaultCallStatus,
  defaultDataStatus,
} from "../../../resources/utils/data/defaultStateVariables";
import {
  CallStatus,
  DataStatus,
  SDataGroupObj,
} from "../../../resources/utils/interfaces/CommonInterfaces";

interface UseHomeProps {
  dataStatus: DataStatus;
  loading: boolean;
}

const useHome = (): UseHomeProps => {
  const { userLogged } = AppContext();
  const {API_CALLER} = userLogged
  const [callStatus, setCallStatus] = useState<CallStatus>(defaultCallStatus);
  const [dataStatus, setDataStatus] = useState<DataStatus>(defaultDataStatus);
  
  useEffect(() => {
    API_CALLER
      .get("ssh_data/")
      .then((res) => {
        setCallStatus({ ...callStatus, readyToCall: true });
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callStatus.readyToCall) {
      API_CALLER
        .get(`ssh_data/getdata&noSort=no`)
        .then((res) => {
          console.log(res);
          setDataStatus({
            ...dataStatus,
            data : new SDataGroup((res.data.data) as SDataGroupObj),
          });
          setCallStatus({ ...callStatus, loading: false });
        })
        .catch((error) => {
          console.log(error);
          //   setCallStatus({ ...callStatus, loading: false });
        });
    }

    return () => {
     // setDataStatus({ ...dataStatus, allData: {} });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callStatus.readyToCall]);


  return { dataStatus, loading: callStatus.loading };
};

export default useHome;
