//import { SelectedRows, TablesStates} from "../../../../resources/utils/interfaces/CommonInterfaces";

import SelectedRows from "../../../../resources/utils/classes/SelectedRows";
import TablesStates from "../../../../resources/utils/classes/TableStates";



export enum DMActionEnum {
    ChangeVariable = "ChangeVariable",
    SetTableState = "SetTableState",
    UpdateSelections = "UpdateSelections",
    CheckAddition = "CheckAddition",
    DeleteFullySelected = "DeleteFullySelected",
    ResetAfterExport = "ResetAfterExport"
}


export enum DMVariables {
    AllSensorTable = "allSensorTable",
    DayTable = "dayTable",
    SingleInfoTable = "singleInfoTable",
    AllSensorSelected = "allSensorSelected",
    DaySelected = "daySelected",
    SignleRowData = "signleRowData"
}


export interface DMStateProps {
    tablesStates: TablesStates
    selectedData: SelectedRows

} 

export interface DMActionProps {
    type: DMActionEnum;
    playload?: {
        variable?:any;
        value: any
    }
}