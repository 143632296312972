import { FONTS } from "../../utils/styles/FontStyles";
import { Choice, Choices, Header, HeaderLine, SelectContainer, SelectWrapper } from "./SelectSC";
import { ArrowDownward } from '@mui/icons-material';
import useSelect from "./UseSelect"
import { memo } from "react";


interface SelectProps {
    values?: string[];
    onChange: (value: any , ...other: any) => any | void;
    className?: string;
}

const Select = ({ values = ['Wolt Oy', 'Nokia', 'Wrastila'], onChange , className}: SelectProps) => {

    const { showDropDownMenu, setShowDropDownMenu, ref, handleSelect, selectedValue } = useSelect({onChange});
    return (
        <SelectContainer
            onClick={() => setShowDropDownMenu(!showDropDownMenu)}
            ref={ref}
            className={className ?? 'noclass--'}
        >
            <SelectWrapper>
                <HeaderLine>
                    <Header
                        className="header--text"
                        theme={{ font: FONTS.inputplaceHolder }}>
                        COMPANY
                    </Header>
                </HeaderLine>
                {selectedValue.length !== 0  &&
                <HeaderLine>
                    <Header
                        className="header--text"
                        theme={{ font: FONTS.selectdSelectCmp }} >
                        {selectedValue}
                    </Header>
                </HeaderLine>  }
                <ArrowDownward
                    className="dropdown--icon "
                />
            </SelectWrapper>
            <Choices showDropDown={showDropDownMenu}>
                {values.map((value, index) => {
                    return (
                        <Choice
                            key={index}
                            onClick={() => handleSelect(value)}
                        >
                            <p>{value}</p>
                        </Choice>)

                })}

            </Choices>

        </SelectContainer>
    )
}

export default memo(Select);