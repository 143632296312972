// Enums
export enum LoginActionType {
  ChangeLoginVariable = "ChangeLoginVariable",
  SubmitLogin = "SubmitLogin",
  ErrorLogin = "ErrorLogin",
  SetLoadingOff = "SetLoadingOff"
}

export enum StateVariables {
  Username = "username",
  Password = "password",
  Submitting = "Submitting"
}

// Interfaces

export interface LoginStateProps {
  loginCredential: {
    username: string;
    password: string;
  },
  submitting: boolean;
  loading: boolean;
  success: boolean;
}

export interface LoginActionProps {
  type: LoginActionType;
  playload?: {
    variable: StateVariables;
    value: any;
  };
}
