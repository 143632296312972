import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import SensorData from "../../../../resources/utils/classes/SensorData";
import { defaultCallStatus } from "../../../../resources/utils/data/defaultStateVariables";
import { CallStatus } from "../../../../resources/utils/interfaces/CommonInterfaces";

type Params = {
  sensorId: string;
};

interface UseGraphProps {
  displayData: SensorData[];
  sensorId: string;
  loading: boolean;
}

const useGraphView = (): UseGraphProps => {
  const { sensorId } = useParams<Params>();
  const [displayData, setDisplayData] = useState<SensorData[]>([]);
  const { userLogged } = AppContext();
  const [callStatus, setCallStatus] = useState<CallStatus>(defaultCallStatus);

  useEffect(() => {
    userLogged.API_CALLER.get(`ssh_data/`)
      .then((res) => {
        setCallStatus({ ...callStatus, readyToCall: true });
      })
      .catch((error) => {
        console.log(error);
        //  setCallStatus({ ...callStatus, readyToCall: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callStatus.readyToCall) {
      userLogged.API_CALLER.get(`ssh_data/getdata/sensorId=${sensorId}`)
        .then((res) => {
          console.log(res);
          //  const dataSorted = (res.data as DataFetchProps).data.sort((a, b) => Number(new Date(a.Time)) - Number(new Date(b.Time)))
          let sensorList = (res.data.data as SensorData[]).map(v => new SensorData(v));
          setDisplayData(sensorList);
          setCallStatus({ ...callStatus, loading: false });
        })
        .catch((error) => {
          console.log(error);
          setCallStatus({ ...callStatus, loading: false });
          setDisplayData([]);
          //   setCallStatus({ ...callStatus, loading: false });
        });
    }
    return () => {
      setDisplayData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callStatus.readyToCall]);

  return {
    displayData,
    loading: callStatus.loading,
    sensorId: (sensorId as string).toLocaleUpperCase(),
  };
};

export default useGraphView;
