import React from "react";
import {
  DataExportContainer,
  TitleBox,
  Header,
  LineBreak,
  DataBox,
  DataTypeTitleBox,
  DataListRows,
  DataRowContainer,
} from "./DataExportSC";
import { ImportExport, ArrowDropDown, Delete } from "@mui/icons-material";
import { FONTS } from "../../../../resources/utils/styles/FontStyles";
import useDataExport, { DropDownEnums } from "./UseDataExport";
import { useOutletContext } from "react-router-dom";
import SelectedRows from "../../../../resources/utils/classes/SelectedRows";
import { DataListOutletProps } from "../../../../resources/utils/interfaces/OutletRelatedInterfaces";
import RowsFirst from "./RowsFirst";

export interface DataExportProps {
  selectedRows: SelectedRows;
  deleteFullySelected: (num: number) => (value: any) => void
}

const DataExport = () => {
  // const [showDropDown, setShowDropDown] = useState<boolean>(true);
  const outletContext: DataListOutletProps = useOutletContext();
  const { selectedRows, deleteFullySelected } = outletContext.dataExportProps;

  const { allSensorSelected, daySelected, signleRowData } = selectedRows
  const { dropDowns, controlDropDowns } = useDataExport();
  const { SensorRows, DayRows, SingleRows } = DropDownEnums;

  return (
    <DataExportContainer>
      <TitleBox>
        <Header theme={{ font: FONTS.tableheaderStyle }}>
          DATA EXPORT LIST
        </Header>
        <ImportExport />
      </TitleBox>
      <DataBox>
        <DataTypeTitleBox
          onClick={() => controlDropDowns(SensorRows)}
          showDropDown={dropDowns[SensorRows]}
        >
          <Header theme={{ font: FONTS.tableheaderStyle }}>
            Sensors with all their data
          </Header>
          <ArrowDropDown className="drop--icon" />
        </DataTypeTitleBox>
        <DataListRows showDropDown={dropDowns[SensorRows]}>
          {allSensorSelected.map((value, index) => {
            const DLT = deleteFullySelected(1);
            return (
              <DataRowContainer key={index}>
                <RowsFirst
                  vals={[
                    {
                      value: value.sesnsorId,
                      varaibleLevel: "SENSOR ID:",
                    },
                    {
                      value: value.sensorMac,
                      varaibleLevel: "MAC ADDRESS:",
                    },
                  ]}
                  widthFull="400px"
                />
                <LineBreak widthFull="400px" />
                <Delete className="delete--icon"
                  onClick={() => DLT(value)}
                />
              </DataRowContainer>
            );
          })}
        </DataListRows>
        {/**       SECTION END                          */}
        {/**       SECTION                          */}

        <DataTypeTitleBox
          onClick={() => controlDropDowns(DayRows)}
          showDropDown={dropDowns[DayRows]}
        >
          <Header theme={{ font: FONTS.tableheaderStyle }}>
            All day data of a sensor
          </Header>
          <ArrowDropDown className="drop--icon" />
        </DataTypeTitleBox>
        <DataListRows showDropDown={dropDowns[DayRows]}>
          {daySelected.map((value, index) => {
              const DLT = deleteFullySelected(2);
            return (
              <DataRowContainer key={index}>
                <RowsFirst
                  widthFull="350px"
                  vals={[
                    { value: value.sesnsorId, varaibleLevel: "SENSOR ID:" },
                    { value: value.day, varaibleLevel: "DATA DATE:" },
                  ]}
                />
                <LineBreak widthFull="350px" />
                <Delete 
                  onClick={() => DLT(value)}
                className="delete--icon" />
              </DataRowContainer>
            );
          })}
        </DataListRows>
        {/**       SECTION END                          */}
        {/**       SECTION                          */}

        <DataTypeTitleBox
          onClick={() => controlDropDowns(SingleRows)}
          showDropDown={dropDowns[SingleRows]}
        >
          <Header theme={{ font: FONTS.tableheaderStyle }}>
            Single Data rows
          </Header>
          <ArrowDropDown className="drop--icon" />
        </DataTypeTitleBox>
        <DataListRows showDropDown={dropDowns[SingleRows]}>
          {signleRowData.map((value, index) => {
            const DLT = deleteFullySelected(3);
            return (
              <DataRowContainer key={index}>
                <RowsFirst
                  widthFull="640px"
                  vals={[
                    {
                      value: value.allRowData.Name,
                      varaibleLevel: "SENSOR ID:",
                    },
                    { value: value.Humidity, varaibleLevel: "HMD:" },
                    { value: value.Temperature, varaibleLevel: "TEMP:" },
                    { value: value.Movement, varaibleLevel: "MOV:" },
                    {
                      value: value.allRowData.Time.toLocaleString(),
                      varaibleLevel: "DATA TIME:",
                    },
                  ]}
                />
                <LineBreak widthFull="640px" />
                <Delete 
                 onClick={() => DLT(value)}
                className="delete--icon" />
              </DataRowContainer>
            );
          })}
        </DataListRows>
      </DataBox>
    </DataExportContainer>
  );
};

export default DataExport;
