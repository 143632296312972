
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SelectBoxContainer } from './SelectboxSC';
 

interface SelectBoxProps {
    value: any;
    onClick?: (value?: any,...other: any) => void;
    className?: string;
    isFullySelect?: boolean;
    isSelected?: boolean;
}   

const SelectBox = ({value, onClick,className, isFullySelect, isSelected} : SelectBoxProps) => {

  return (
    <SelectBoxContainer 
        className={className ? className : '--noclass'}
        onClick={() =>{
          if (onClick && !isFullySelect) onClick(value)
        }}
    >
        {(isFullySelect || isSelected) && 
        <CheckBoxIcon className='circle--icon' />  }
    </SelectBoxContainer>
  )
}

export default SelectBox


