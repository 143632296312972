import { Dispatch, useEffect, useState, SetStateAction } from "react";
import { AppContext } from "../../../context/AppContext";
import PrivateKey from "../../../resources/utils/classes/PrivateKey";

interface UsePrivateKeysProps {
  loading: boolean;
  privateKeys: PrivateKey[];
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}


const usePrivateKeys = (): UsePrivateKeysProps => {
  const { userLogged } = AppContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [readyToCall, setReadyToCall] = useState<boolean>(false);
  const [privateKeys, setPrivateKeys] = useState<PrivateKey[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    userLogged.API_CALLER('credentials_controller/').then(() => {
      setReadyToCall(true);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //////; 
  //////;
  useEffect(() => {
    if (readyToCall) {
      userLogged.API_CALLER.get("credentials_controller/get_credentials").then(
        (res) => {
          if (res.data.privateKeys) {
            setPrivateKeys(
              (res.data.privateKeys as any[]).map((i) => new PrivateKey(i))
            );
          }
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToCall]);

  return { loading, privateKeys, showModal, setShowModal };
};

export default usePrivateKeys;
