import styled from "styled-components";


export const DashboardContainer = styled.div`
    width: 100%;
    height: 100%;


`;
export const DashboardWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

`;



