import styled from "styled-components";


export let Header = styled.h1`
    ${props => props.theme.font}
`;

export const TopbarContainer = styled.div`
    width: 100%;
    height: 60px;
 //   background: red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
`;

export const PageHead = styled.div`
    height: 100%;
   // padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NotesBarSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const UserBasicInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
    height: 100%;
   // border-left: 2px solid #DFE0EB;
`;


export const NotificationSection = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & > .note--icons {
        color:#DFE0EB;
        transition: all 300ms ease-out;
        &:hover {
            opacity: 0.9;
            color: black;
            transform: translateY(-2px);
        }
    }
`
export const UserProfile = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 6px;
    transition: all 300ms ease-out;
    object-fit: cover;
    &:hover {
            opacity: 0.8;
          //  color: black;
            transform: translateY(-2px);
        }
`;

export const LineHead = styled.div`
    width: 1px;
    height: 60%;
    border-left: 1px solid #DFE0EB;
    margin-right: 9px;
    
`

 


