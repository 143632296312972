import UserLocal from "../../resources/utils/classes/UserLocal";
import { NavbarDataProps } from "../../resources/utils/interfaces/CommonInterfaces";

export enum ContextActionEnum {
  ChangeVariable = "ChangeVariable",
  LoginRegister = "LoginRegister",
  LogoutRegister = "LogoutRegister",
  SetLoadingOff = "SetLoadingOff",
  SetLoadingOn = "SetLoadingOn",
  SetUserApi = "SetUserApi",
  UpdateToken = "UpdateToken",
}

export enum ContextStateVariables {
  IsLoggedIn = "isLoggedIn",
  CuurentPage = "currentPage",
}

// Interfaces

export interface ContextStateProps {
  isLoggedIn: boolean;
  loading: boolean;
  currentPage: NavbarDataProps;
  userLogged: UserLocal;
}

export interface ContextActionProps {
  type: ContextActionEnum;
  playload?: {
    variable?: ContextStateVariables;
    value: any;
  };
}
