import { FONTS } from "../../../../resources/utils/styles/FontStyles";
import { DataInfoBox, DataRowFirstSection, Text } from "./DataExportSC";

interface Val {
    varaibleLevel: string;
    value: string;
}

const RowsFirst = (props: {vals : Val[],  widthFull: string;}) => {
     const {vals,widthFull} = props
    return (
        <DataRowFirstSection
        widthFull={widthFull}
        >
            {vals.map((value, index) => {
                return (
                    <DataInfoBox
                        key={index}
                    >
                        <Text
                            className='--idvariable'
                            theme={{ font: FONTS.navbarFont }}
                        >{value.varaibleLevel}</Text>
                        <Text
                            className='--idvalue'
                            theme={{ font: FONTS.navbarFont }}
                        > {value.value} </Text>
                    </DataInfoBox>
                )
            })}
        </DataRowFirstSection>
    )
}

export default RowsFirst


/**
[{
                  value: value.sesnsorId,
                  varaibleLevel: 'SENSOR ID:'
                }, {
                  value: value.sensorMac,
                  varaibleLevel: 'MAC ADDRESS:'
                }]
 */