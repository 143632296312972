import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig= {
  apiKey: "AIzaSyAHl9S2roQKmRJkM4jqJhJ6O0B9fvxK60g",
  authDomain: "cortex-platform.firebaseapp.com",
  projectId: "cortex-platform",
  storageBucket: "cortex-platform.appspot.com",
  messagingSenderId: "766802154754",
  appId: "1:766802154754:web:94c912252bc4c5ab809e1b",
  measurementId: "G-RKWBHCK85N"
};

const app : FirebaseApp = initializeApp(firebaseConfig);
export const auth : Auth = getAuth(app);
export const storage = getStorage(app);

