import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import ANIMATIONS from "../../../resources/utils/styles/OtherCss";

export let Header = styled.h1`
  ${(props) => props.theme.font}
`;

export let Text = styled.p`
  ${(props) => props.theme.font}
`;

export const DataListContainer = styled.div`
   width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${ANIMATIONS.basicpageAnimation}
  //overflow: auto;
`;

export const DataListWrapper = styled.div`
 width: 98%;
  height: 98%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
`;

export const SelectListSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  gap: 20px;
  flex: 1;
`;

const linkCustoms = css`
  width: 220px;
  max-height: 50px;
  height: 80%;
  text-decoration: none;
`;

export const DefactoLink = styled.div`
   ${linkCustoms}
`;

export const ListLink = styled(Link)`
  ${linkCustoms}

`;

export const ListChoice :any = styled.div`
  width: 100%;
  height: 100%;
   border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-out;
  cursor: pointer;
  text-decoration: none;
  background:${({wantedRoute} : any) =>  wantedRoute && 'rgba(190, 190, 190, 0.843)'};
  transform: ${({wantedRoute} : any) =>  wantedRoute && 'scale(1.02)'};
  &:hover {
   // background: rgba(190, 190, 190, 0.643);
     background:${({wantedRoute} : any) =>  !wantedRoute && 'rgba(190, 190, 190, 0.643)'};
    transform: ${({wantedRoute} : any) =>  !wantedRoute && 'scale(1.05)'};
   //transform: scale(1.05);
  }
`;

export const TableList = styled.div`
  width: 100%;
  flex: 10;
  background: #fff;
  border: 1px solid #f9fafc;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ButtonSection = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .--csvlink {
    text-decoration: none;
  }
`;

export const PrimaryButtonSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
