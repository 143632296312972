import React from 'react'
import { DataBlockContainer, Header, DataCell, MetricIcon, MetricTitle, MetricValue } from './DataBlockSC'
import Temperature from '../../../../resources/images/icons/temperature.svg'
import Humidity from '../../../../resources/images/icons/humidity.svg';
import Movement from '../../../../resources/images/icons/movement.svg';
import { FONTS } from "../../../../resources/utils/styles/FontStyles";
import { SubRoutePaths } from '../../../../resources/utils/enums/CommonEnums';
import SensorData from '../../../../resources/utils/classes/SensorData';

interface DataBlockProps {
    latestData: SensorData
  //  onClick : () => void;
    name: string
}

const DataBlock = (props : DataBlockProps) => {
    const {latestData, name} = props
    return (
        <DataBlockContainer
            to={`${SubRoutePaths.GRAPH_VIEW}=${latestData.Name}`}
        //    onClick={() => onClick()}
        >
            <Header
                theme={{ font: FONTS.sensorTitleBox }}>
                {name.toUpperCase()}
            </Header>
            <DataCell>
                <MetricIcon src={Temperature} />
                <MetricTitle>
                    Temperature:
                </MetricTitle>
                <MetricValue>
                {parseFloat(latestData.Temperature).toFixed(1)} C
                </MetricValue>
            </DataCell>
            <DataCell>
                <MetricIcon src={Humidity} />
                <MetricTitle>
                    Humidity:
                </MetricTitle>
                <MetricValue>
                {parseFloat(latestData.Humidity).toFixed(1)} %
                </MetricValue>
            </DataCell>
            <DataCell>
                <MetricIcon src={Movement} />
                <MetricTitle>
                    Movement:
                </MetricTitle>
                <MetricValue>
                {parseInt(latestData.Movement)} T
                </MetricValue>
            </DataCell>


        </DataBlockContainer>
    )
}

export default DataBlock