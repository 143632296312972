import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { AppContext } from "../../../../context/AppContext";

interface UseNewPrivateKey {
  privateKeyFile: File | null;
  setPrivateKeyFile: Dispatch<SetStateAction<File | null>>;
  loading: boolean;
  companyList: string[];
  upload: () => void
}

const useNewPrivateKey = (): UseNewPrivateKey => {
  const [loading, setLoading] = useState<boolean>(true);
  const [privateKeyFile, setPrivateKeyFile] = useState<File | null>(null);
  const { userLogged } = AppContext();
  const [companyList, setCompanyList] = useState<string[]>([]);

  useEffect(() => {
    userLogged.API_CALLER.get("user_management/companyList").then((res) => {
      setCompanyList(res.data.companies);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TMP

  const upload = () => {
    let formData = new FormData();
    formData.append('NoS',privateKeyFile as File);
    userLogged.API_CALLER.post('credentials_controller/add_credentials', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res => {
        console.log(res);
    })
  }

  return { privateKeyFile, setPrivateKeyFile, loading, companyList,upload };
};

export default useNewPrivateKey;
