import React from 'react'
import { useOutletContext } from 'react-router-dom'
import SensorData from '../../../../resources/utils/classes/SensorData'
import { HomeOutletProps } from '../../../../resources/utils/interfaces/OutletRelatedInterfaces'
import DataBlock from '../DataBlock/DataBlock'
import { DataBlocksWrapper } from './BlocksSC'

export interface BlocksProps  {
    latestData: Map<string, SensorData>
}

const Blocks = () => { 
  const outletContext  : HomeOutletProps = useOutletContext();
  const {latestData} = outletContext.blockProps 
  console.log(latestData)
  return (
    <DataBlocksWrapper>
         {Array.from(latestData.keys()).map((sensor, index) => {
          return (
              <DataBlock
              key={index} 
             name={sensor}
             latestData={latestData.get(sensor) as SensorData}
         /> 
          )
         })}
    </DataBlocksWrapper>
  )
}

export default Blocks


/**
 {latestData.map((value, index) => {
          return (
            <DataBlock
           key={index} 
          onClick={() => setDisplayedData(value.Name)}
          latestData={value}
      />  
          )
        })}
 */

        /**
         return (
            <DataBlock
            key={index} 
           name={sensor}
           latestData={latestData.get(sensor) as SensorData}
       />  
         */