import { useState, Dispatch, SetStateAction } from "react"
import {  UserEditBasic, UserEditPassword } from "../../../../resources/utils/interfaces/CommonInterfaces"


interface SubmitCallStatus {
    loading: boolean;
    success: boolean;
}

interface SubmitProps {
    setBasicInfo: Dispatch<SetStateAction<UserEditBasic>>;
    setPasswordInfo: Dispatch<SetStateAction<UserEditPassword>>;
    basicInfo: UserEditBasic;
    passwordInfo: UserEditPassword
}

export interface SubmitCall {
    callStatus: SubmitCallStatus
}

const useFormSubmit = (props: SubmitProps) : SubmitCall  => {
    const [callStatus] = useState<SubmitCallStatus>({
        loading: false,
        success: false
    });
//const [errors, setErrors] = useState<string[]>([]);
//const {setBasicInfo, setPasswordInfo, basicInfo, passwordInfo} = props;

    
 return {callStatus}

}

export default useFormSubmit;