import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../resources/firebase";
import UserLocal from "../resources/utils/classes/UserLocal";
import {
  RoutesAvailable,
  USER_PERMISSIONS,
} from "../resources/utils/data/CommonAppVariables";
import { MainRoutePaths } from "../resources/utils/enums/CommonEnums";
import { AppContextProps } from "./AppContext";
import contextDefaultValues from "./contextReducer/reducerDefault";
import reducer from "./contextReducer/reducerFucntion";
import {
  ContextActionProps,
  ContextActionEnum,
  ContextStateVariables,
} from "./contextReducer/reducerIntsEnums";

interface UseAppContextProps {
  passedToValues: AppContextProps;
  loading: boolean;
}


const useAppContext = (): UseAppContextProps => {
  const [state, dispatch] = useReducer(reducer, contextDefaultValues);
  const location = useLocation();
  const { isLoggedIn, loading, currentPage, userLogged } = state;
  //
  const login = async (email: string, password: string): Promise<boolean> => {
    let success: boolean = false;
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        success = true;
        dispatch({ type: ContextActionEnum.LoginRegister });
      })
      .catch(() => {
        success = false;
      });
    return success;
  };
  //
  const logout = () => {
    signOut(auth)
      .then(() => {
        console.log("logged out");
      })
      .catch(() => {
        console.error("Logout failed");
      });
  };

  const checkPageViewRight = (page?: MainRoutePaths): boolean => {
    const rightThere = USER_PERMISSIONS[userLogged.role].pageView.find(
      (p) => p === (page ? page : currentPage.path)
    );
    return rightThere ? true : false;
  };

  const updateUserStatus = (user : UserLocal) => dispatch({type: ContextActionEnum.UpdateToken, playload: {value: {user}}});

  let changeAuth = () => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user);
        const loginAction: ContextActionProps = {
          type: ContextActionEnum.LoginRegister,
          playload: {
            value: (user as any),
          },
        };
        dispatch(loginAction);
      } else dispatch({ type: ContextActionEnum.LogoutRegister });
      setTimeout(() => {
        dispatch({ type: ContextActionEnum.SetLoadingOff });
      }, 0);
    });
    return unsubscribe;
  };


  useEffect(() => {
    return changeAuth();
  }, []);



  useEffect(() => {
    let mainPath = "/" + location.pathname.split("/")[1];
    const currentPageFound = RoutesAvailable().find(
      (v) => v.path === (mainPath as MainRoutePaths)
    );
    if (currentPageFound) {
      dispatch({
        type: ContextActionEnum.ChangeVariable,
        playload: {
          variable: ContextStateVariables.CuurentPage,
          value: currentPageFound,
        },
      });
    }
  }, [location]);
  return {
    passedToValues: {
      isLoggedIn,
      login,
      logout,
      currentPage,
      checkPageViewRight,
      userLogged,
      updateUserStatus
    },
    loading,
  };
};

export default useAppContext;
