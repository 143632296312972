import { FormValues } from "./UseAddUser";


const  validatePassword= (p: string) => {
    let success = true;
    if (p.length < 8) {
       // Your password must be at least 8 characters
       success = false;
    }
    if (p.search(/[a-z]/i) < 0) {
        // Your password must contain at least one letter
        success = false;
    }
    if (p.search(/[A-Z]/) < 0) {
        // Your password must contain at least Upper letter
        success = false
    }
    if (p.search(/[0-9]/) < 0) {
        // Your password must contain at least one digit
        success = false;
    }

    return success;
}

const validEmail = (e : string) => {
    // eslint-disable-next-line no-useless-escape
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(e).search (filter) !== -1;
}

const validate = (values : FormValues) : string[] => {
    const {password, passwordRepeat, email, emailRepeat, role} = values
    let errors : string[]= [];
    if (password !==  passwordRepeat) errors.push('Passwords must be the same');
    if (!validatePassword(password)) errors.push('PASSWORD REJECTED (NOT STRING)')
    if (!validEmail(email)) errors.push('EMAIL FORMAT NOT VALID');   
    if (email !==  emailRepeat) errors.push('Emails must be the same');
    if (role === null) errors.push('Role must be specified');

    return errors;
}

export default validate;