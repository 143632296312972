import Button from "../../../resources/customs/button/Button";
import {
  ButtonSection,
  DataListContainer,
  SelectListSection,
  TableList,
  PrimaryButtonSection,
  ListLink,
  Header,
  ListChoice,
  DefactoLink,
  DataListWrapper
} from "./DatalistSC";
import { mediumButtonSize } from "../../../resources/customs/button/ButtonSC";
import {
  ButtonIcons,
  SubRoutePaths,
} from "../../../resources/utils/enums/CommonEnums";
import { FONTS } from "../../../resources/utils/styles/FontStyles";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { dataListLocCheck } from "../../../resources/utils/hooks/LocationCheckHooks";
import useDatalist from "./UseDatalist";
import Loader from "../../../resources/customs/loader/Loader";
import { DataListOutletProps } from "../../../resources/utils/interfaces/OutletRelatedInterfaces";
import { useDataMemory } from "./UseDataMemory";
import { CSVLink } from "react-csv";
import { csvHeaders } from "../../../resources/utils/data/CommonAppVariables";

const { ALL_DATA, DATA_EXPORT } = SubRoutePaths;

const Datalist = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const {
    tablesStates,
    setTableStates,
    updateSelections,
    selectedRows,
    deleteFullySelected,
    setDownloadCounter,
    nameTheCSVFile
  } = useDataMemory();
  const { sensorRows, loading, getDayData } = useDatalist();
  return (
    <DataListContainer>
      <DataListWrapper>
      <SelectListSection>
        <DefactoLink
          onClick={() => navigate(-1)}
          children={
            <ListChoice wantedRoute={dataListLocCheck(location, ALL_DATA)}>
              {" "}
              <Header theme={{ font: FONTS.selectionListData }}>
                ALL DATA
              </Header>{" "}
            </ListChoice>
          }
        />
        <ListLink
          to={DATA_EXPORT}
          children={
            <ListChoice wantedRoute={dataListLocCheck(location, DATA_EXPORT)}>
              {" "}
              <Header theme={{ font: FONTS.selectionListData }}>
                DATA EXPORT
              </Header>{" "}
            </ListChoice>
          }
        />
      </SelectListSection>
      <TableList>
        {loading ? (
          <Loader />
        ) : (
          <Outlet
            context={
              {
                allDataProps: {
                  sensorRows,
                  tablesStates,
                  setTableStates,
                  selectedRows,
                  getDayData,
                },
                dataExportProps: { selectedRows, deleteFullySelected },
              } as DataListOutletProps
            }
          />
        )}
      </TableList>
      <ButtonSection>
        <PrimaryButtonSection>
          <Button
            buttonSize={mediumButtonSize}
            icon={ButtonIcons.AddIcon}
            onClick={() => updateSelections()}
            disabled={!tablesStates.checkMultipleSelect() || !dataListLocCheck(location, ALL_DATA)}
          >
            ADD TO EXPORT
          </Button>
  
        </PrimaryButtonSection>
        <CSVLink
        aria-readonly
        headers={csvHeaders}
          className="--csvlink"
          filename={nameTheCSVFile()}
          data={selectedRows.convertToSensorData()}
          onClick={() => {
            if (!selectedRows.canExport() || !dataListLocCheck(location, DATA_EXPORT)) return false;

            setDownloadCounter((current) => current + 1);
            return true
          }}
        >
          <Button
            buttonSize={mediumButtonSize}
            icon={ButtonIcons.ExportIcon}
            disabled={!selectedRows.canExport() || !dataListLocCheck(location, DATA_EXPORT)}
          >
            EXPORT TO CSV
          </Button>{" "}
        </CSVLink>
      </ButtonSection>
      </DataListWrapper>
    </DataListContainer>
  );
};

export default Datalist;
