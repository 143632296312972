import axios, { AxiosInstance } from "axios";
import { Auth } from "firebase/auth";
import {
  FirebaseStorage,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { UserRoles } from "../enums/CommonEnums";
import { isTokenExpired, parseJwt } from "../hooks/Commonhooks";
import { AttrsJwt, Privilages } from "../interfaces/CommonInterfaces";

interface UserLocalProps {
  name: string;
  role: UserRoles;
  email: string;
  company: string;
  photoURL: string | undefined | null;
  adminPrivileges: null | Privilages;
  API_CALLER: AxiosInstance;
  uploadPicture: (
    storage: FirebaseStorage,
    file: File,
    callback: (user: UserLocal) => void,
    nullify?: boolean
  ) => void;
}

class UserLocal implements UserLocalProps {
  public name: string = "";
  public email: string = "";
  public company: string = "";
  public role: UserRoles = UserRoles.Guest;
  public adminPrivileges: null | Privilages = null;
  public photoURL: string | undefined | null = null;
  public API_CALLER: AxiosInstance;

  constructor(accessToken: string, photoURL?: string, auth?: Auth) {
    if (auth) {
      try {
        const { role, name, email, company }: AttrsJwt = parseJwt(accessToken);
        this.name = name;
        this.role = role;
        this.email = email as string;
        this.company = company ? (company as string) : "";
        if (photoURL) this.photoURL = photoURL;
        switch (role) {
          case UserRoles.Admin_Customer:
            this.adminPrivileges = {
              fullControl: false,
              company: this.company,
              callLink: "admin_customer_management",
            };
            break;
          case UserRoles.Admin:
            this.adminPrivileges = {
              fullControl: true,
              company: this.company,
              callLink: "user_management",
            };
            break;
          default: 
            this.adminPrivileges = null;
            break;  
        }
      } catch (error) {
        console.error(error);
      }
    }

    let API_CALLER = axios.create({
      headers: {
        Authorization: accessToken,
      },
      //baseURL: process.env.REACT_APP_SERVER_URL,
      baseURL: 'https://cortexdasho.herokuapp.com/'
    });
    if (auth)
      API_CALLER.interceptors.request.use(async (config) => {
        if (config.headers) {
          let currentToken = config.headers.Authorization as string;
          if (isTokenExpired(currentToken)) {
            if (auth.currentUser) {
              let newToken = await auth.currentUser.getIdToken();
              config.headers.Authorization = newToken;
            }
          }
        }
        return config;
      });
    this.API_CALLER = API_CALLER;
  }

  /** 
  public updatebaseUrl = (newsc: string) => {
    this.photoURL = newsc;
  } **/
  public uploadPicture = (
    storage: FirebaseStorage,
    file: File,
    callback: (user: UserLocal) => void,
    nullify?: boolean
  ) => {
    if (nullify) {
      this.API_CALLER.put("/user_management/updateOwnUser", {
        photoURL: "nullify",
      }).then(() => {
        this.photoURL = null;
        callback(this);
      });
    } else {
      const storageRef = ref(
        storage,
        `/profilePics/${this.email}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          console.log(progress);
        },
        (err) => {
          console.log(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log("URL is");
            console.log(url);
            this.API_CALLER.put("/user_management/updateOwnUser", {
              photoURL: url,
            })
              .then(() => {
                this.photoURL = url;
                callback(this);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      );
    }
  };
}

export default UserLocal;

/** 
 https://firebasestorage.googleapis.com/v0/b/noim
 */
