import React from "react";
import {
  Container,
  FirstSection,
  ProfileCoverSection,
  Wrapper,
  PictureCover,
  ProfileImage,
  Header,
  BreakLine,
  FormBox,
  DisplayTitle,
  Section,
  Text,
  Div,
  RemovePicture,
} from "./UsersTypicalSC";
import RandomUserIcon from "../../../../resources/images/icons/userTopIcon.svg";
import { FONTS } from "../../../../resources/utils/styles/FontStyles";
import Button from "../../../../resources/customs/button/Button";
import {
  BUserInfoState,
  ButtonIcons,
  InputIcons,
  PUserInfoState,
} from "../../../../resources/utils/enums/CommonEnums";
import { consistentSize } from "../../../../resources/customs/button/ButtonSC";
import InputLabel from "../../../components/InputLabel/InputLabel";
import useUsersTypical from "./UseUsersTypical";
import { ArrowDropDown, CloudUpload, Close } from "@mui/icons-material";
import useDisplay from "./UseDisplay";
import FileUploaderC from "../../../../resources/customs/fileUploader/FileUploaderC";

const UsersTypical = () => {
  const { displayInfo, controlDisplay } = useDisplay();
  const {
    basicInfo,
    passwordInfo,
    controlBasicForm,
    controlPasswordForm,
    nameBefore,
    fileImage,
    typicalPhotoURL,
    uploadFile,
    setFileImage,
    setNullify,
    nullify,
    diplayImage,
  } = useUsersTypical();
  const { name, phoneNumber, emailAddress } = basicInfo;
  const { oldPassword, newPassword, repeatNewPassword } = passwordInfo;

  return (
    <Container>
      <Wrapper>
        <ProfileCoverSection>
          <FirstSection>
            <PictureCover>
              <ProfileImage
                src={(() => {
                  //    if (nullify) return RandomUserIcon;
                  if (fileImage) return URL.createObjectURL(fileImage as File);
                  else if (typicalPhotoURL) return typicalPhotoURL;
                  return RandomUserIcon;
                })()}
                alt={"profile picture"}
              />
              {typicalPhotoURL && (
                <RemovePicture
                  onClick={() => console.log("Clciked")}
                  nullify={nullify}
                  imageBefore={typicalPhotoURL}
                  children={
                    <Close
                      className="icon--nullify"
                      onClick={() => setNullify(!nullify)}
                    />
                  }
                />
              )}
            </PictureCover>
            <Div>
              <Header theme={{ font: FONTS.metricTitle }}>{nameBefore}</Header>
              <FileUploaderC
                types={["png", "svg", "JPEG", "jpg"]}
                onSelect={diplayImage}
                onDrop={diplayImage}
                handleChange={diplayImage}
                name={fileImage ? fileImage.name : 'noname'}
              >
                <Text
                  className="upload--text"
                  theme={{ font: FONTS.tablerowStyle }}
                >
                  Upload new image <CloudUpload />
                </Text>
              </FileUploaderC>
              <Text className="newfile--name">
                {( fileImage && !nullify) && fileImage.name}
                {fileImage && !nullify && (
                  <Close
                    className="close--icon"
                    onClick={() => setFileImage(null)}
                  />
                )}
              </Text>
            </Div>
          </FirstSection>
          <Button
            disabled={fileImage === null && nullify !== true}
            icon={ButtonIcons.UpgradeIcon}
            buttonSize={consistentSize}
            onClick={() => uploadFile()}
          >
            {nullify ? "DELETE PICTURE" : "SAVE NEW PICTURE"}
          </Button>
        </ProfileCoverSection>
        <BreakLine />
        <Section>
          <DisplayTitle
            onClick={() => controlDisplay(1)}
            theme={{ showDropDown: displayInfo.basicInfo }}
          >
            <Header theme={{ font: FONTS.tableheaderStyle }}>
              BASIC USER INFORMATION
            </Header>
            <ArrowDropDown className="drop--icon" />
          </DisplayTitle>
          <FormBox
            theme={{ showDropDown: displayInfo.basicInfo }}
            name="basicInfoForm"
          >
            <InputLabel
              label="Name"
              inputProps={{
                value: name,
                name: BUserInfoState.Name,
                placeholder: "FULL NAME",
                icon: InputIcons.UserIcon,
                disabled: true,
                onChange: (e) => controlBasicForm(e),
              }}
            />
            <InputLabel
              label="Phone number"
              inputProps={{
                value: phoneNumber,
                name: BUserInfoState.PhoneNumber,
                placeholder: "PHONE NUMBER",
                icon: InputIcons.PhoneIcon,
                onChange: (e) => controlBasicForm(e),
              }}
            />
            <InputLabel
              label={"Email address"}
              inputProps={{
                value: emailAddress,
                name: BUserInfoState.EmailAddress,
                placeholder: "Email address",
                disabled: true,
                icon: InputIcons.ContactMail,
                onChange: (e) => controlBasicForm(e),
              }}
            />
            <Button buttonSize={consistentSize}>SAVE</Button>
          </FormBox>
        </Section>

        <Section>
          <DisplayTitle
            onClick={() => controlDisplay(2)}
            theme={{ showDropDown: displayInfo.passwordInfo }}
          >
            <Header theme={{ font: FONTS.tableheaderStyle }}>
              PASSWORD USER INFORMATION
            </Header>
            <ArrowDropDown className="drop--icon" />
          </DisplayTitle>
          <FormBox
            theme={{ showDropDown: displayInfo.passwordInfo }}
            name="PasswordInfoForm"
          >
            <InputLabel
              label="Old password"
              inputProps={{
                value: oldPassword,
                name: PUserInfoState.OldPassword,
                placeholder: "OLD PASSWORD",
                type: "password",
                icon: InputIcons.PasswordIcon,
                onChange: (e) => controlPasswordForm(e),
              }}
            />
            <InputLabel
              label="New password"
              inputProps={{
                value: newPassword,
                name: PUserInfoState.NewPassword,
                placeholder: "NEW PASSWORD",
                type: "password",
                icon: InputIcons.PasswordIcon,
                onChange: (e) => controlPasswordForm(e),
              }}
            />
            <InputLabel
              label={"Repeat new password"}
              inputProps={{
                value: repeatNewPassword,
                name: PUserInfoState.RepeatNewPassword,
                placeholder: "REPEAT NEW PASSWORD",
                type: "password",
                icon: InputIcons.PasswordIcon,
                onChange: (e) => controlPasswordForm(e),
              }}
            />
            <Button buttonSize={consistentSize}>SAVE</Button>
          </FormBox>
        </Section>
      </Wrapper>
    </Container>
  );
};

export default UsersTypical;
