import React from "react";
import { FilterAlt, Search, FormatListNumbered } from "@mui/icons-material";
import {
  IconBox,
  HeaderCell,
  SelectCell,
  SearchBoxWrapper,
  SearchInput,
  TableCenter,
  TableContainer,
  TableHeaderContainer,
  TopLayer,
  Header,
  TableRowContainer,
  TableFooter,
  FooterWrapper,
  Text,
  SecondRowSection,
  ArrowBack,
  ArrowFront,
  SelectCellWrapper,
} from "./TableSC";
import { FONTS } from "../../../resources/utils/styles/FontStyles";
import useTable from "./UseTable";
import SelectBox from "../../../resources/customs/selectbox/Selectbox";
import { TableState } from "./tableUtils/TypesEnums.";
import useTableHooks from "./useTableHooks";

export interface Row {
  valueProp: any;
  label: string;
}

export interface TableProps {
  headers: Row[];
  data: any[];
  onSelect?: (value?: any) => void;
  onRowOpen?: (value?: any) => void;
  multiple?: boolean;
  noSingleSelect?: boolean;
  selectOpen?: boolean;
  rowsApplied?: any[];
  tableState?: TableState | null;
  setTableState?: (value: any) => void;
}

const Table = (props: TableProps) => {
  const { headers, multiple, rowsApplied, onRowOpen, noSingleSelect } = props;
  const _tableHooks = useTableHooks(headers);
  const {
    filterWord,
    setFilterWord,
    dataFiltered,
    handleSelectRow,
    selectedRow,
    seeLimit,
    increaseLimit,
    decreaseLimit,
    multipleSet,
    setMultipleSet,
    addNewItemToMultiple,
    multipleSelectedItems,
  } = useTable(props, _tableHooks);
  const { max, min } = seeLimit;
  return (
    <TableContainer>
      <TopLayer>
        {multiple && (
          <IconBox
            selected={multipleSet}
            onClick={() => {
              setMultipleSet((current) => (current === null ? true : !current));
              handleSelectRow({});
            }}
          >
            <FormatListNumbered className="" />
          </IconBox>
        )}
        <IconBox>
          <FilterAlt className="" />
        </IconBox>
        <SearchBoxWrapper>
          <Search className="--icon" />
          <SearchInput
            value={filterWord}
            onChange={(e) => setFilterWord(e.target.value)}
          />
        </SearchBoxWrapper>
      </TopLayer>
      <TableCenter>
        <TableHeaderContainer>
          {multipleSet && (
            <SelectCellWrapper>
              <SelectCell>
                <Header theme={{ font: FONTS.tableheaderStyle }}>N</Header>
              </SelectCell>
            </SelectCellWrapper>
          )}
          {headers.map((value, index) => {
            return (
              <HeaderCell key={index}>
                <Header theme={{ font: FONTS.tableheaderStyle }}>
                  {value.label}
                </Header>
              </HeaderCell>
            );
          })}
        </TableHeaderContainer>
        {dataFiltered?.map((value, index) => {
          let fullySelected: boolean = false;
          if (multiple && rowsApplied) {
            fullySelected = _tableHooks._includes(rowsApplied, value);
          }
          if (index >= min && index <= max)
            return (
              <TableRowContainer
                key={index}
                onClick={
                  !multipleSet
                    ? () => {
                        if (onRowOpen && !fullySelected) onRowOpen(value);
                        else {
                          if (!noSingleSelect) handleSelectRow(value);
                        }
                      }
                    : null
                }
                selectedRow={_tableHooks._objEqual(value, selectedRow)}
                multiSelectRow={fullySelected}
              >
                {multipleSet && (
                  <SelectCellWrapper>
                    <SelectCell isFullySelect={fullySelected}>
                      <SelectBox
                        className="select--box"
                        value={value}
                        isFullySelect={fullySelected}
                        isSelected={_tableHooks._includes(
                          multipleSelectedItems,
                          value
                        )}
                        onClick={(val) => addNewItemToMultiple(val)}
                      />
                    </SelectCell>{" "}
                  </SelectCellWrapper>
                )}
                {headers.map((vtem, s) => {
                  return (
                    <HeaderCell key={s}>
                      <Header theme={{ font: FONTS.tablerowStyle }}>
                        {value[vtem.valueProp]}
                      </Header>
                    </HeaderCell>
                  );
                })}
              </TableRowContainer>
            );
          else return null;
        })}
      </TableCenter>
      <TableFooter>
        <FooterWrapper>
          <Text theme={{ font: FONTS.tablefooterFont }}>
            {min + 1}-{max + 1} of {dataFiltered?.length}
          </Text>
          <SecondRowSection>
            <ArrowBack onClick={() => decreaseLimit()} disabled={!(min > 0)} />
            <ArrowFront
              onClick={() => increaseLimit()}
              disabled={!(dataFiltered.length > max)}
            />
          </SecondRowSection>
        </FooterWrapper>
      </TableFooter>
    </TableContainer>
  );
};

export default Table;

/**
     {Object.keys(value).map((keyName, i) => {
      
        let keyNameExists = headers.find(
            (f) => f.valueProp === keyName
        );
        console.log(keyName)
        if (keyNameExists)
            return (
                <HeaderCell key={i}>
                    <Header theme={{ font: FONTS.tablerowStyle }}>
                        {value[keyNameExists.valueProp]}
                    </Header>
                </HeaderCell>
            );
        else return null;  
    
    })} 
 **/
