import { Row } from "./Table";


export interface UseTableHooks {
    _includes: (array: any[], value: any) => boolean;
    _delete: (array: any[], value: any) => any[];
    _objEqual: (objOne: any, objTwo: any) => boolean;
}

const useTableHooks = (headers: Row[]) : UseTableHooks => {

    const _includes = (array: any[], value: any) => {
        let found = array.find((v) => {
            for (let i in headers) {
               if (!(v[headers[i].valueProp] === value[headers[i].valueProp])) return false;
            }
            return true;
        })
        return found ? true : false;
    }

    const _delete = (array: any[], value: any) : any[]=> {
        let filteredDelete = array.filter(v => {
            let match = true;
            for (let i in headers) {
                if (!(v[headers[i].valueProp] === value[headers[i].valueProp])) match = false;
             }
             return !match;
        })
        return filteredDelete;
    }

    const _objEqual = (objOne: any, objTwo: any) => {
        for (let i in headers) {
            if (!(objOne[headers[i].valueProp] === objTwo[headers[i].valueProp])) return false;
         }
         return true;
    }

    return {_includes, _delete, _objEqual}
} 

export default useTableHooks;