import React from 'react'
import Loader from '../../resources/customs/loader/Loader'
import { LoadingPageContainer } from './LoadingPageSC'

const LoadingPage = () => {
  return (
    <LoadingPageContainer>
        <Loader scale={2} />
    </LoadingPageContainer>
  )
}

export default LoadingPage