import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import { MainRoutePaths, UserRoles } from '../resources/utils/enums/CommonEnums';


const ProtectedRoute = () => {
    const {checkPageViewRight, userLogged} = AppContext();
    const {role} = userLogged
     return checkPageViewRight() ? <Outlet /> : <Navigate to={role === UserRoles.Partner ? MainRoutePaths.DATA_LIST : MainRoutePaths.HOME} />

    //else return null;
}

export default ProtectedRoute;