import { MainRoutePaths, UserRoles } from "../enums/CommonEnums";
import { NavbarDataProps } from "../interfaces/CommonInterfaces";
import { SVG_ICON_VALUES } from "./SvgIconValues";
import {
  Home,
  People,
  ListAlt,
  Assessment,
  AddBusiness,
  Person,
  Key,
} from "@mui/icons-material";
// Locals
const partnerViewRights = [MainRoutePaths.DATA_LIST];

const userViewRights = [
  ...partnerViewRights,
  MainRoutePaths.HOME,
  MainRoutePaths.USERPAGE,
  MainRoutePaths.REPORTS,
];
const adminCustomerViewRights = [
  ...partnerViewRights,
  MainRoutePaths.MARKETPLACE,
  MainRoutePaths.HOME,
  MainRoutePaths.USERPAGE,
  MainRoutePaths.USERS,
  MainRoutePaths.REPORTS,
];

const adminViewRights = [...adminCustomerViewRights, MainRoutePaths.PRIVATE_KEYS];

// To be exported

export const RoutesAvailable = (role?: UserRoles): NavbarDataProps[] => {
  return [
    {
      page: "Home",
      path: MainRoutePaths.HOME,
      icon: SVG_ICON_VALUES.home,
      iconUi: <Home className="icon--nav" />,
    },
    {
      page: "Users",
      path: MainRoutePaths.USERS,
      icon: SVG_ICON_VALUES.users,
      iconUi: <People className="icon--nav" />,
    },
    {
      page: 'Private Keys',
      path: MainRoutePaths.PRIVATE_KEYS,
      icon: SVG_ICON_VALUES.marketplace,
      iconUi: <Key className="icon--nav"  />
    },
    {
      page: "User Page",
      path: MainRoutePaths.USERPAGE,
      icon: SVG_ICON_VALUES.users,
      iconUi: <Person className="icon--nav" />,
    },

    {
      page: "Data List",
      path: MainRoutePaths.DATA_LIST,
      icon: SVG_ICON_VALUES.dataList,
      iconUi: <ListAlt className="icon--nav" />,
    },
    {
      page: "Reports",
      path: MainRoutePaths.REPORTS,
      icon: SVG_ICON_VALUES.reports,
      iconUi: <Assessment className="icon--nav" />,
    },
    {
      page: "Marketplace",
      path: MainRoutePaths.MARKETPLACE,
      icon: SVG_ICON_VALUES.marketplace,
      iconUi: <AddBusiness className="icon--nav" />,
    },
   
  ];
};

export const USER_PERMISSIONS = {
  Partner: {
    pageView: partnerViewRights,
  },
  User: {
    pageView: userViewRights,
  },
  Admin: {
    pageView: adminViewRights,
  },
  Admin_Customer: {
    pageView: adminCustomerViewRights
  },
  Guest: {
    pageView: [],
  },
};

export const csvHeaders = [
  {
    key: "Time",
    label: "Time",
  },
  {
    key: "Name",
    label: "Name",
  },
  {
    key: "Mac",
    label: "Mac",
  },
  {
    key: "Movement",
    label: "Movement",
  },
  {
    key: "Temperature",
    label: "Temperature",
  },
  {
    key: "Humidity",
    label: "Humidity",
  },
];
