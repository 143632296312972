import {
  Header,
  LogoDisplay,
  NavbarContainer,
  NavbarItem,
  NavbarLink,
  NavbarList,
  NavbarWrapper,
} from "./NavbarSC";
import CortexLogo from "../../../resources/images/logos/cortexLogo.svg";
import { FONTS } from "../../../resources/utils/styles/FontStyles";
import { RoutesAvailable } from "../../../resources/utils/data/CommonAppVariables";
import { AppContext } from "../../../context/AppContext";

const Navbar = () => {
  const {currentPage, userLogged, checkPageViewRight} = AppContext();
  return (
    <NavbarContainer>
      <NavbarWrapper>
        <LogoDisplay src={CortexLogo} alt="Cortex logo" />
        <NavbarList>
          {RoutesAvailable(userLogged.role).map((value, index) => {
            const { path, page, iconUi} = value;
            const chosen : boolean = path === currentPage.path
            if(checkPageViewRight(path))
            return (
              <NavbarItem key={index} chosen={chosen}>
                <NavbarLink to={path}>
                {/** <NavbarIcon src={icon} alt="Navbar item" />  **/}
                  {iconUi}
                  <Header theme={{ font: FONTS.navbarFont }} >{page}</Header>
                </NavbarLink>
              </NavbarItem>
            );
            else return null;
          })}
        </NavbarList>
      </NavbarWrapper>
    </NavbarContainer>
  );
};

export default Navbar;
