import styled from "styled-components";


export const RightbarContainer = styled.div`
    flex: 5;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;


    /** 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; **/
`;
export const RightbarWrppaer = styled.div`
     display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    height: 100%;

`;

export const BoardContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    height: calc(100% - 70px);
    display: flex;
    justify-content: center;
    align-items: center;

    
  
`;

export const BoardWrapper = styled.div`
    width: 95%;
    height: 95%;
    position: relative;

 //   ;
   // overflow: hidden;
  //  background: green;
`;

