import {
  Delete,
  Edit,
  ImportExport,
  Add,
  Upgrade,
  Cloud,
  FindReplace,
} from "@mui/icons-material";

const IconsJSX = {
  deleteIcon: () => <Delete className="icon--button" />,
  editIcon: () => <Edit className="icon--button" />,
  exportIcon: () => <ImportExport className="icon--button" />,
  addIcon: () => <Add className="icon--button" />,
  upgradeIcon: () => <Upgrade className="icon--button" />,
  uploadIcon: () => <Cloud className="icon--button" />,
  findReplace: () => <FindReplace className="icon--button" />,
};

export default IconsJSX;
