import { useState } from "react";

interface DisplayObj {
    basicInfo: boolean;
    passwordInfo: boolean;
}

export interface UseDisplay {
    displayInfo: DisplayObj,
    controlDisplay: (num: number) => void;
}

const useDisplay = () : UseDisplay=> {
    const [displayInfo, setDisplayInfo] = useState<DisplayObj>({
        basicInfo:true, 
        passwordInfo: true
    });

    const controlDisplay = (num: number) => {
        if (num === 1) setDisplayInfo({...displayInfo, basicInfo: !displayInfo.basicInfo})
        else  setDisplayInfo({...displayInfo, passwordInfo: !displayInfo.passwordInfo})
    }

    return {displayInfo, controlDisplay}
}



export default useDisplay;