import Navbar from "../../layouts/dashboardLayouts/navbar/Navbar"
import Rightbar from "../../layouts/dashboardLayouts/rightbar/Rightbar"
import { DashboardContainer, DashboardWrapper,  } from "./DashboardSC"
const Dashboard = () => {
  return (
    <DashboardContainer>
        <DashboardWrapper>
            <Navbar />
            <Rightbar />
        </DashboardWrapper>
    </DashboardContainer>
  )
}

export default Dashboard