import { useState, useEffect } from "react";
import { AppContext } from "../../../../context/AppContext";
import { DeleteUserLocal } from "./DeleteUser";

interface FormStatus {
  success: boolean;
  submit: boolean;
}

export interface UseDeleteUser {
  deleteConfirm: () => void;
  status: FormStatus;
}

const useDeleteUser = (props: DeleteUserLocal): UseDeleteUser => {
  const { user, closeModal, callback } = props;
  const { userLogged } = AppContext();
  const { API_CALLER } = userLogged;
  const [status, setStatus] = useState<FormStatus>({
    success: false,
    submit: false,
  });

  const deleteConfirm = () => {
    setStatus({ ...status, submit: true });
  };

  useEffect(() => {
    if (status.submit) {
      setTimeout(() => {
        API_CALLER.delete(
          `user_management/deleteUser&&uid=${user.userId}`
        ).then((res) => {
          setStatus((current) => {
            return { ...current, submit: false };
          });
          closeModal();
          callback();
        });
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.submit]);
  return { deleteConfirm, status };
};

export default useDeleteUser;
