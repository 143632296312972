import { useEffect, useState } from "react";
import SensorData from "../../../../resources/utils/classes/SensorData";
//import { DataKey } from "../../../../resources/utils/enums/CommonEnums";
import { DataGrpah, /**SensorData **/} from "../../../../resources/utils/interfaces/CommonInterfaces";
import { GraphProps } from "./Graph";


/** 
interface UseGraph {
    dataGraph: DataGrpah[]
} **/
const useGraph = (props: GraphProps) => {
    const {data, dataKey} = props
    const [dataGraph, setDataGraph] = useState<DataGrpah[]>([]);
    useEffect(() => {
        let dGraph  = [...data].map((value : SensorData) => {
            return {time: value.Time, [dataKey] :value.showFloat(dataKey)}
        });
        setDataGraph(dGraph as unknown as DataGrpah[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataKey]) 

    //console.log(dataGraph);
    return {dataGraph}
} 


export default useGraph;