import styled from "styled-components";
import ANIMATIONS from "../../../../resources/utils/styles/OtherCss";


export let Header = styled.h1`
  ${(props) => props.theme.font}
  font-size: 11px;
  font-weight: 500;
  transform: translateY(-2.5px);
`;

export let Text = styled.p`
  ${(props) => props.theme.font}
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    ${ANIMATIONS.basicpageAnimation}
`;

export const TableWrapper = styled.div`
    width: 100%;
    height: calc(100% - 35px);

`;
export const RouteTables = styled.div`
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .arrow-back--icon {
        transition: all 300ms ease-out;
        margin-right: 5px;
        transform: scale(0.8);
        &:hover {
            opacity: 0.6;
        }
    } 
`;

