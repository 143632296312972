import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useReducer,
} from "react";
import { AppContext } from "../../context/AppContext";
import loginDefaultValues from "./reducerUtils/DefaultValues";
import {
  LoginActionProps,
  LoginActionType as Type,
  StateVariables,
} from "./reducerUtils/EnumsInterfaces";
import reducer from "./reducerUtils/ReducerFunction";

interface UseLoginPageProps {
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  loginAction: (e: FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  success: boolean;
  submitting: boolean;
  username: string;
  password: string;
}

// Reducer is used

const useLoginPage = (): UseLoginPageProps => {
  const [state, dispatch] = useReducer(reducer, loginDefaultValues);
  const { login } = AppContext();
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const changeVariableAction: LoginActionProps = {
      type: Type.ChangeLoginVariable,
      playload: {
        variable: e.target.name as StateVariables,
        value: e.target.value,
      },
    };
    dispatch(changeVariableAction);
  };
  const loginAction = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: Type.SubmitLogin });
  };
  const { loginCredential, submitting, loading, success } = state;
  const { username, password } = loginCredential;
  useEffect(() => {
    if (submitting) {
      (async () => {
        console.log("Reached here");
        let sucess = await login(username, password);
        if (!sucess) dispatch({ type: Type.ErrorLogin });
        setTimeout(() => {
            dispatch({type : Type.SetLoadingOff})
        }, 1000)
      })(); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  return {
    handleInputChange,
    username,
    password,
    loginAction,
    loading,
    submitting,
    success
  };
};

export default useLoginPage;
