import {  useNavigate } from "react-router-dom";
import { SensorRow } from "../../../../resources/utils/interfaces/CommonInterfaces";


interface UseAllDataProps {
    openRow: (value: SensorRow) => void 
}

const useAllData = () : UseAllDataProps=> {
    const navigate = useNavigate()

    const openRow = (value: SensorRow) => {
        navigate(`sensor&${value.sesnsorId}`)
    }

    return {openRow}

}
export default useAllData;