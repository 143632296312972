import styled from "styled-components";


export let Header = styled.h1`
  ${(props) => props.theme.font}
  font-weight: 400;
  font-size: 16px;
`;


export const DeleteUserContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DeleteUserBox = styled.div`
    width: 500px;
    height: 180px;
    background: #fff;
    border: 1px solid #F9FAFC;
    position: relative;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:20px;
    align-items: center;
`;

export const LoaderSection = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(4px);

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ConfirmationSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`
