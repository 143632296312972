import styled from "styled-components";
import ANIMATIONS from "../../../../resources/utils/styles/OtherCss";

export let Header = styled.h1`
  ${(props) => props.theme.font}
`;

export let Text = styled.p`
  ${(props) => props.theme.font}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  ${ANIMATIONS.basicpageAnimation}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
`;

export const ProfileCoverSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 25px;
`;

export const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  h1 {
    font-size: 20px;
  }
  .upload--text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: all 300ms ease-out;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .newfile--name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    font-weight: 500;
  }
  .close--icon {
    transition: all 300ms ease-out;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const PictureCover = styled.div`
  padding: 10px;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  border: 3px solid #dfe0eb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

interface RemovePictureProps {
  nullify: boolean;
  imageBefore: string | null | undefined;
}

export const RemovePicture = styled.div<RemovePictureProps>`
  position: absolute;
  border-radius: 50%;
  // height: 0%;
  // width: 0%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 200ms ease-out;
  display: flex;
  opacity: 0;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  ${({ nullify }) =>
    nullify
      ? "height: 90%;width: 90%; background: #cacfd1a6; opacity: 1;"
      : "height: 0%;width: 0%; opacity: 0;"}
  .icon--nullify {
    transform: scale(1.8);
    color: rgb(44, 44, 44);
    padding: 5px;
    background: ${({ nullify }) =>
    nullify ? "white" : "rgba(158, 143, 143, 0.541)"};
    // background: rgba(158, 143, 143, 0.541);
    border-radius: 50%;
    transition: all 200ms ease-out;
    :hover {
      background: ${({nullify}) => nullify  ? 'rgba(158, 143, 143, 0.541)' : 'white'};
    }
  }

  ${PictureCover}:hover & {
    ${({ nullify, imageBefore }) => 
      (!nullify &&
      imageBefore) &&
      "height: 90%;width: 90%; background: #cacfd1a6;"}
    opacity: 1;
  }
`;

export const BreakLine = styled.div`
  width: 70%;
  height: 1px;
  border-bottom: 3px solid #dfe0eb;
  border-radius: 5px;
`;

export const Section = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;
`;

export const FormBox = styled.form`
  width: 100%;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  transition: all 500ms ease-out;
  max-height: ${(props) => (props.theme.showDropDown ? "300px" : "0px")};
  // max-height: ${({ showDropDown }: any) => (showDropDown ? "300px" : "0px")};
  overflow: hidden;

  & > button {
    margin-top: 1rem;
  }
`;

export const DisplayTitle = styled.div`
  width: 400px;
  justify-self: flex-start;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease-out;
  margin-top: 15px;
  &:hover {
    opacity: 0.7;
  }

  & > h1 {
    font-size: 16px;
    font-weight: 500;
    //  text-transform: none;
  }
  & > .drop--icon {
    transition: all 200ms ease-out;
    transform: ${(props) => props.theme.showDropDown && "rotate(180deg)"};
  }
`;
