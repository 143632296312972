import {PersonOutline, LockOpen, Phone, ContactMail, Business} from '@mui/icons-material'; 



const IconsInputJSX = {
    userIcon: <PersonOutline className='input--icon' />,
    passwordIcon: <LockOpen  className='input--icon' />,
    phoneIcon: <Phone className='input--icon' />,
    contactMail: <ContactMail className='input--icon' />,
    businessIcon: <Business className='input--icon'  /> ,
    
}


export default IconsInputJSX;