import Input, { InputProps } from "../../../resources/customs/input/Input";
import { InputLabelContainer, LabelContainer, Label } from "./InputLabelSC";

interface InputLabelProps {
  className?: string;
  label?: string;
  inputProps?: InputProps 
}

const InputLabel = (props: InputLabelProps) => {
 // const {icon, value, className, placeholder, type, name,onChange} = props.inputProps as InputProps
  return (
    <InputLabelContainer className={props.className ?? 'noClass'}>
      <LabelContainer>
        <Label>{props.label ?? ''}</Label>
        <span>:</span>
      </LabelContainer>
      <Input
        {...props.inputProps}
        className="input--inside"
      />
    </InputLabelContainer>
  );
};

export default InputLabel;
