import styled from "styled-components";
import ANIMATIONS from "../../../../resources/utils/styles/OtherCss";

export const UsersAdminContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${ANIMATIONS.basicpageAnimation}
  overflow: auto;


 


`;

export const UsersAdminWrapper = styled.div`
  width: 98%;
  height: 98%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;


`;

export const UsersTable = styled.div`
  width: 100%;
  margin-top: 5px;
  flex: 10;
  background: #fff;
  border: 1px solid #f9fafc;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

`;

export const UserChangeCnt = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(26, 25, 25, 0.447);
  position: absolute;
  ${ANIMATIONS.basicpageAnimation}
`;

export const AddUserSection = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export let Header = styled.h1`
  ${(props) => props.theme.font}
`;
