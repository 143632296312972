import { getDateWithoutTime, getTimeOnly } from "../hooks/Commonhooks";
import {
  SDataGroupObj,
  SensorRow,
  SensorDayRow,
  SingleRowSensorData,
} from "../interfaces/CommonInterfaces";
import SensorData from "./SensorData";

interface SDataGroupProps {
  allData: Map<string, SensorData[]>;
  latestData: Map<string, SensorData>;
  daySensorData: Map<string, SensorDayRow[]>;
  setRows: () => SensorRow[];
}

class SDataGroup implements SDataGroupProps {
  public allData: Map<string, SensorData[]> = new Map<string, SensorData[]>();
  public latestData: Map<string, SensorData> = new Map<string, SensorData>();
  public daySensorData: Map<string, SensorDayRow[]> = new Map<
    string,
    SensorDayRow[]
  >();

  constructor(objects?: SDataGroupObj) {
    if (objects) {
      //this.allData = new Map(Object.entries(objects));
      for (let i in objects) {
        const mappedSensors = objects[i].map((v) => new SensorData(v));
        this.allData.set(i, mappedSensors);
      }
      this.allData.forEach((v, key) => {
        let latest = [...v].reduce((a, b) => {
          return new Date(a.Time) > new Date(b.Time) ? a : b;
        });
        this.latestData.set(key, latest);
      });
      this.setDayData();
    }
  }

  setRows = () => {
    if (this.latestData.size > 0) {
      let returnArray = Array.from(this.latestData.keys()).map(
        (sesnsorId): SensorRow => {
          const sensor = this.latestData.get(sesnsorId) as SensorData;
          const { Time, Mac } = sensor;
          const latestDataTime = new Date(Time).toLocaleString();
          const sensorMac = Mac;
          const allSensorData = this.allData.get(sesnsorId) as SensorData[];
          return { sesnsorId, sensorMac, latestDataTime, allSensorData };
        }
      );
      return returnArray;
    }
    return [];
  };

  /**
   * 
   export interface SensorDayRow {
  day: string;
  latestDataTime: string;
  allDayData: SensorData[]
}
   */

  private setDayData = () => {
    this.allData.forEach((sensors, key) => {
      let returnedArray: SensorDayRow[] = [];
      if (sensors) {
        let days: string[] = [];
        sensors.forEach((v) => {
          let dayWithoutTime = getDateWithoutTime(new Date(v.Time));
          if (!days.includes(dayWithoutTime)) days.push(dayWithoutTime);
        });

        days.forEach((v) => {
          let allDayData = sensors
            .filter(
              (v2) =>
                getDateWithoutTime(new Date(v2.Time)) ===
                getDateWithoutTime(new Date(v))
            )
            .map((v): SingleRowSensorData => {
              const { Time, Temperature, Humidity, Movement } = v;
              return {
                Time: getTimeOnly(new Date(Time)),
                Temperature,
                Humidity,
                Movement,
                allRowData: v,
              };
            });

          let latest = [...allDayData].reduce((a, b) => {
            return new Date(a.Time) > new Date(b.Time) ? a : b;
          });
          returnedArray.push({
            sesnsorId: key,
            day: v,
            latestDataTime: latest.Time.toLocaleString(),
            allDayData,
          });
          this.daySensorData.set(key, returnedArray);
        });
      }
    });
  };
}

export default SDataGroup;
