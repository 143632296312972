import styled from 'styled-components';


export let Header = styled.h1`
  ${(props) => props.theme.font}
`;

export const SelectContainer = styled.div`
width: 100%;
//  height: 100%;
height: 45px;
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;

cursor: pointer;
//border-radius: 3px;
gap: 3px;
padding: 0;
position: relative;

// Tmp
margin-top: 15px;

`;

export const SelectWrapper = styled.div`
    width:100%;
    height: 100%;
   // padding: 10px 5px;
   border: 1px solid #1151F5;
   background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  
    &:hover  {
        opacity: 0.8;
        .dropdown--icon {
            opacity: 0.8;
        }
    }
    & > .dropdown--icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right:5px;
        color: #666666;
    }

`;

export const HeaderLine = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    & > .drop--down--arrow {
        color: #666666;
        margin-right: 7px;
    }
    & > .header--text {
        margin-left: 7px;
    }
`;

export const Choices : any = styled.div`
    position: absolute;
    width:100%;
    max-height: 300px;
    top: 100%;
    //left:0;
    height: ${({showDropDown} : any) => showDropDown ? 'auto' : '0'};
    background:white;
    z-index:2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    border-bottom: ${({showDropDown} : any) => showDropDown && '1px solid #1151F5'};
    border-right: ${({showDropDown} : any) => showDropDown && '1px solid #1151F5'};
    border-left: ${({showDropDown} : any) => showDropDown && '1px solid #1151F5'};

     //border: 1px solid #175034;
    border-radius: 3px;
`;

export const Choice : any= styled.div`
    height: 30px;
    //padding: 10px 0;
    background: ${({stateBackground} : any) => stateBackground};
    width: 100%;
    display: flex;
   // justify-content: flex-start;
    justify-content: space-between;
    align-items: center;
    transition: all 350ms ease-out;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
        .check--icon {
            color: #0d346ff3;
        }
    }
    .check--icon {
        color: black;
        margin-right: 2px;
    }
`;