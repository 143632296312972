import React from 'react'
import { NewPrivateKeyContainer,ButtonSection, PrivateBox, HeaderUpload, PivateKeyText} from './NewPrivateKeySC'
import {  CloudUpload, Close } from "@mui/icons-material";
import FileUploaderC from "../../../../resources/customs/fileUploader/FileUploaderC";
import useNewPrivateKey from './UseNewPrivateKey';
import Loader from '../../../../resources/customs/loader/Loader';
import Select from '../../../../resources/customs/select/Select';
import Button from '../../../../resources/customs/button/Button';

interface NewPrivateKeyProps {
  closeModal: () => void;
}


const NewPrivateKey = (props: NewPrivateKeyProps) => {
  const {closeModal} = props;
  const {privateKeyFile, setPrivateKeyFile, loading, companyList} = useNewPrivateKey();
  
  return (
    <NewPrivateKeyContainer>
      {loading ? <Loader scale={1.2} /> : 
      <PrivateBox>
        <FileUploaderC
          types={['pem']}
          onSelect={setPrivateKeyFile}
          onDrop={setPrivateKeyFile}
        //  handleChange={setPrivateKeyFile}
        >
        <HeaderUpload>
          Upload new private key  <CloudUpload className='cloud--icon' />
        </HeaderUpload>
        </FileUploaderC>
      <PivateKeyText>
          {privateKeyFile && privateKeyFile.name}
          {privateKeyFile && <Close className='close--icon' onClick={() => setPrivateKeyFile(null)} />}
      </PivateKeyText>
      <Select 
        values={companyList}
        onChange={() => null}
        className="select--box"
      />

      <ButtonSection>
        <Button
          className='--btn'
          
        >
            CONFIRM
        </Button>
        <Button
          className='--btn'
          onClick={() => closeModal()}
        >
            CANCEL
        </Button>
      </ButtonSection>
      </PrivateBox>}
    </NewPrivateKeyContainer>
  )
}

export default NewPrivateKey