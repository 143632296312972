import styled, {createGlobalStyle}from 'styled-components';
import { WEBIT } from '../resources/utils/styles/OtherCss';

export const  AppParentContainer = styled.div`
    width: 100%;
    min-width: 900px;
    height: 100vh;
    min-height: 700px;
    background: #F2F4F8;

    //background: red;
`;

export const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        font-family: 'Montserrat', sans-serif;
       ${WEBIT.scrollbar} 
    }
`;
