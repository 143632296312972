import React, { ReactNode } from "react";
import { FileUploader } from "react-drag-drop-files";

interface FileUploaderProps {
  children?: ReactNode | ReactNode[];
  types?: string[];
  onDrop?: Function;
  handleChange?: Function;
  onSelect?: Function;
  name?: string;
}

const FileUploaderC = (props: FileUploaderProps) => {
  return <FileUploader  fileOrFiles={false}   disable={false} {...props} />;
};

export default FileUploaderC;
