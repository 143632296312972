import { Link } from "react-router-dom";
import styled from "styled-components";
import { FONTS } from "../../../../resources/utils/styles/FontStyles";

export let Header = styled.h1`
  ${(props) => props.theme.font}
`;

export let Text = styled.p`
  ${(props) => props.theme.font}
`;

export const DataBlockContainer = styled(Link)`
  background: white;
  height: 130px;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease-out;
  text-decoration: none;
  cursor: pointer;
  & > h1 {
    margin-top: 15px;
  }
  &:hover {
    background: rgba(190, 190, 190, 0.643);
    transform: scale(1.05);
  }

  
`;

export const DataCell = styled.div`
  width: 95%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const MetricIcon = styled.img`
  width: 20px;
  height: 55%;
  margin-right: 8px;
`;

export const MetricTitle = styled.p`
  ${FONTS.metricTitle}
  width: 100px;
`;

export const MetricValue = styled.p`
  ${FONTS.metricTitle}
  width: 50px;
`;
