import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { AppContext } from "../../../../context/AppContext";
import { UserRoles } from "../../../../resources/utils/enums/CommonEnums";
import { userConstruct } from "../../../../resources/utils/hooks/Commonhooks";
import {
  FormStatus,
  Privilages,
} from "../../../../resources/utils/interfaces/CommonInterfaces";
import validate from "./Validation";

export interface FormValues {
  name: string;
  company: string;
  email: string;
  emailRepeat: string;
  password: string;
  passwordRepeat: string;
  role: UserRoles;
}

const formDefaultValues: FormValues = {
  name: "",
  company: "",
  email: "",
  emailRepeat: "",
  password: "",
  passwordRepeat: "",
  role: UserRoles.User,
};

interface UserAddUserLocal {
  formValues: FormValues;
  controlForm: (e: ChangeEvent<HTMLInputElement>) => void;
  handleRoleComp: (e: UserRoles, name: string) => void;
  submitForm: (e: FormEvent<HTMLFormElement>) => void;
  status: FormStatus;
  errors: string[];
  privileges: Privilages;
}

interface UseAddUserInbound {
  addNewUser: (callback: Function) => void;
}

// State is used

const useAddUser = ({ addNewUser }: UseAddUserInbound): UserAddUserLocal => {
  const [formValues, setFormValues] = useState<FormValues>(formDefaultValues);
  const [errors, setErrors] = useState<string[]>([]);
  const { userLogged } = AppContext();

  const { API_CALLER } = userLogged;
  const [status, setStatus] = useState<FormStatus>({
    submitted: false,
    loading: false,
    success: null,
  });

  const controlForm = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "company") value = value.toUpperCase();
    setFormValues((current) => {
      return { ...current, [name]: value };
    });
  };
  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(formValues);
    setErrors(errors);
    setStatus({
      submitted: true,
      loading: true,
      success: errors.length === 0 ? true : false,
    });
  };

  const addUserAction = async (): Promise<boolean> => {
    let success = true;
    try {
      const userAdd = await API_CALLER.post(
        `${userLogged.adminPrivileges?.callLink}/adduser`,
        {
          newUser: {
            ...userConstruct(formValues),
            company: userLogged.adminPrivileges?.fullControl
              ? formValues.company
              : userLogged.adminPrivileges?.company,
          },
        }
      );
      success = await userAdd.data.userAdded;
      if (!success)
        setErrors((current) => [
          ...current,
          "USER EXISTS BEFORE OR SERVER ERROR",
        ]);
    } catch (error) {
      console.error(error);
      setErrors((current) => [...current, "ERROR"]);
      success = false;
    }
    return success;
  };
  const handleRoleComp = (value: any, name: string) => {
    setFormValues((current) => {
      return { ...current, [name]: value };
    });
  };

  useEffect(() => {
    const { submitted, success } = status;
    if (submitted) {
      if (success) {
        (async () => {
          const actionVar = await addUserAction();
          addNewUser(() => console.log("New User submiteed"));
          setStatus((current) => {
            return {
              ...current,
              submitted: false,
              loading: false,
              success: actionVar,
            };
          });
        })();
      } else {
        setStatus((current) => {
          return { ...current, submitted: false, loading: false };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.submitted]);
  return {
    formValues,
    controlForm,
    handleRoleComp,
    submitForm,
    status,
    errors,
    privileges: userLogged.adminPrivileges as Privilages,
  };
};

export default useAddUser;

//
