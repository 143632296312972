import styled from "styled-components";

export let Text = styled.p`
    color: white;
    margin-left: 15px;
    font-weight: 300;
`;

export const LoginPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginParentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 180px 0;
  //background: red;
  ;
`

export const LoginBox = styled.div`
  width: 400px;
  // height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const LogoDisplay = styled.img`
  width: 270px;
  height: 40px;
`;

export const FieldSection = styled.form`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 40px;
  & > .button--cls {
    margin-top: 30px;
  }
`;

export const FieldWrapper = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #1151f5;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //  padding: 0 10px;
`;

export const IconDisplay = styled.img`
  width: 30px;
  height: 20px;
  margin-left: 10px;
`;

export const InputField = styled.input`
  background: transparent;
  width: 85%;
  height: 95%;
  border: none;
  outline: none;
  font-size: 15px;

  ::placeholder {
    color: #666666;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const LoaderErrorBox = styled.div`
  width: 100%;
  display: flex;
  bottom: 0;
 //height: 5px;
  position: absolute;
  justify-content: center;
  align-items: center;
  //background: green;
`;

export const ErrorBox = styled.div`
  width: 100%;
  height: 80px;
  border-radius: 2px;
  background: #1151f5;
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;

  animation-name: example;
  animation-duration: 1s;

  @keyframes example {
  0%  { left:60px; }
//  50%  { left:20px;}
  100% { left:0px;}
}

`
