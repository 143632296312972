import styled from "styled-components";
import ANIMATIONS from "../../../../resources/utils/styles/OtherCss";


export const AllDataContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    position: absolute;
    ${ANIMATIONS.basicpageAnimation}
`;
export const TableWrapper = styled.div`
    width: 100%;
    height: calc(100% - 30px);
`;
export const RouteTables = styled.div`
    width: 100%;
    height: 30px;
`;