import styled from "styled-components";
import ANIMATIONS from "../../../../resources/utils/styles/OtherCss";

export let Header = styled.h1`
  ${(props) => props.theme.font}
 ;
`;

export let Text = styled.p`
  ${(props) => props.theme.font}
 ;
`;



export const DataExportContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    ${ANIMATIONS.basicpageAnimation}
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const TitleBox = styled.div`
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    & > h1 {
        font-size: 18px;
        font-weight: 400;
    }
`;

export const DataBox = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    .jooo {
        width: 100%;
        height: 100px;
        background: red;
    }
`;

export const DataTypeTitleBox : any= styled.div`
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 200ms ease-out;
    margin-top: 15px;
    &:hover {
        opacity: 0.7;
    }

    & > h1 {
        font-size: 16px;
        font-weight: 500;
      //  text-transform: none;
    }
    & > .drop--icon {
        transition: all 200ms ease-out;
        transform: ${({showDropDown} : any) => showDropDown && 'rotate(180deg)'};
    }
`;

export const DataListRows : any = styled.div`
    width: 100%;
    transition: all 500ms ease-out;
   // height: fit-content;
  // height: max-content;
    max-height: ${({showDropDown} : any) => showDropDown ? '300px' : '0px'};
    overflow: hidden;

`;

export const DataRowContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    .delete--icon {
        opacity: 0.6;
        transform: scale(0.7);
        transition: all 240ms ease-out;
        &:hover {
            transform: scale(0.8) translateY(-2px);
            opacity: 0.4;
        }
    }
`;

export const DataInfoBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
`;

export const DataRowFirstSection : any = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    width: ${({widthFull}: any) => widthFull};
  //  background: red;
    gap: 20px;
    .--idvalue {
        font-size: 12px;
        font-weight: 700;
    }
    .--idvariable {
        font-size: 12px;

    }
`;

export const LineBreak : any = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1px;
    width: calc(100% - ${({widthFull}: any) => widthFull});
    opacity: 0.6;
    border-radius: 10px;
    border-bottom: 1px solid #363740;
`;

