export interface Limits {
  max: number;
  min: number;
}
export interface TableDataStatus {
  filterWord: string;
  dataFiltered: any;
  selectedRow: any;
}

export enum DataStatusVars {
  FilterWord = "filterWord",
  DataFiltered = "dataFiltered",
  SelectedRow = "selectedRow",
}

export interface TableState {
  dataStatus: TableDataStatus;
  seeLimit: Limits;
  multipleSet: boolean | null;
  multipleSelectedItems: any[];
}
