import styled from "styled-components";



export const GraphContainer = styled.div`
    width: 70%;
    min-width: 500px;
    margin-top: 10px;
    height: 25%;
    background: white;
    border-radius: 5px;
    border: 2px solid rgba(0,0,0,0.1);
`;


