import React from "react";
import { Navigate, Outlet, useOutletContext, useParams } from "react-router-dom";
import {
  SensorDayRow,
  SensorRow,
} from "../../../../resources/utils/interfaces/CommonInterfaces";
import { DataListOutletProps } from "../../../../resources/utils/interfaces/OutletRelatedInterfaces";
import { sensorListLabels } from "../../../components/table/tableUtils/LabelVars";
import Table from "../../../components/table/Table";
import { AllDataContainer, RouteTables, TableWrapper } from "./AllDataSC";
import useAllData from "./UseAllData";
import { AllDataOutletProps } from "../../../../resources/utils/interfaces/OutletRelatedInterfaces";
import TablesStates from "../../../../resources/utils/classes/TableStates";
import SelectedRows from "../../../../resources/utils/classes/SelectedRows";

export interface AllDataProps {
  sensorRows: SensorRow[];
  tablesStates: TablesStates;
  setTableStates: (stateNumber: number) => (value: any) => void;
  getDayData: (sensorId: string) => SensorDayRow[] | undefined 
  selectedRows: SelectedRows;
}

const AllData = () => {
  const outletContext: DataListOutletProps = useOutletContext();
  const { sensorRows, tablesStates, setTableStates, selectedRows, getDayData } =
    outletContext.allDataProps;
  const { openRow } = useAllData();
  let params = useParams();
  if (params.sensorId) {
    const dayRows = getDayData(params.sensorId);
    if (dayRows)
    return (
      <Outlet
        context={
          {
            dayDayProps: {
              dayRows: dayRows as SensorDayRow[] ,
              tablesStates,
              selectedRows,
              setTableStates,
            },
          } as AllDataOutletProps
        }
      />
    );
    return <Navigate to="../" />
  }
  return (
    <AllDataContainer>
      <RouteTables />
      <TableWrapper>
        <Table
          headers={sensorListLabels}
          data={sensorRows}
          tableState={tablesStates.allSensorTable}
          setTableState={setTableStates(1)}
          rowsApplied={selectedRows.allSensorSelected}
          onRowOpen={(val) => openRow(val)}
          noSingleSelect
          multiple
        />
      </TableWrapper>
    </AllDataContainer>
  );
};

export default AllData;
