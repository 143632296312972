import { BackIconCon, GraphSecondrayContainer, GraphViewContainer, Header, LoadingContainer, MetricIcon, MetricRow, SensorTitle, SensorTitleBox } from "./GraphViewSC"
import Temperature from '../../../../resources/images/icons/temperature.svg'
import Humidity from '../../../../resources/images/icons/humidity.svg';
import Movement from '../../../../resources/images/icons/movement.svg';
import { FONTS } from "../../../../resources/utils/styles/FontStyles";
import Graph from "../grap/Graph";
import { DataKey } from "../../../../resources/utils/enums/CommonEnums";
import { ArrowBackIosNew } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import useGraphView from "./UseGraphView";
import Loader from "../../../../resources/customs/loader/Loader";


const GraphView = () => {

  const { displayData, loading, sensorId } = useGraphView();
  const navigate = useNavigate()
  if (loading) return (<LoadingContainer>
    <Loader />
  </LoadingContainer>)
  else {
    if (displayData.length === 0) return <GraphSecondrayContainer>
      <Header>
        NO SENSOR WITH THIS ID IN THE DATA
      </Header>

    </GraphSecondrayContainer>;
    return (
      <GraphViewContainer
        show={displayData.length !== 0}
      >

        <SensorTitleBox>
          <BackIconCon
            onClick={() => navigate('../')}
          >
            <ArrowBackIosNew className="--icon" />
          </BackIconCon>
          <SensorTitle>

            {sensorId}
          </SensorTitle>
        </SensorTitleBox>
        <MetricRow>
          <MetricIcon src={Temperature} />
          <Header
            theme={{ font: FONTS.metricTitle }}
          >
            Temperature
          </Header>
        </MetricRow>
        <Graph
          dataKey={DataKey.Temperature}
          data={displayData}
        />

        <MetricRow>
          <MetricIcon src={Humidity} />
          <Header
            theme={{ font: FONTS.metricTitle }}
          >
            Humidity
          </Header>
        </MetricRow>
        <Graph
          dataKey={DataKey.Humidity}
          data={displayData}
        />
        <MetricRow>
          <MetricIcon src={Movement} />
          <Header
            theme={{ font: FONTS.metricTitle }}
          >
            Movement
          </Header>
        </MetricRow>
        <Graph
          dataKey={DataKey.Movement}
          data={displayData}
        />


      </GraphViewContainer>
    )
  }
}

export default GraphView



/**
 <BackIcon 
          onClick={() => setDisplayedData()}
        />
 */