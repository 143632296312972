import { LoaderContainer } from './LoaderSC'

interface LoaderProps {
  scale?: number
}

const Loader = ({scale} : LoaderProps) => {
  return (
    <LoaderContainer
      scale={scale}
    ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></LoaderContainer>
  )
}

export default Loader