import { Location } from "react-router-dom";
import { MainRoutePaths, SubRoutePaths } from "../enums/CommonEnums";

export const dataListLocCheck = (location: Location, wanted: SubRoutePaths) => {
  if (wanted === SubRoutePaths.ALL_DATA) {
    //   let spilted = location.pathname.split("/");
    if (
      location.pathname.includes(MainRoutePaths.DATA_LIST) &&
      !location.pathname.includes(SubRoutePaths.DATA_EXPORT)
    )
      return true;
    else return false;
  } else if (wanted === SubRoutePaths.DATA_EXPORT) {
    if (
      location.pathname ===
      `${MainRoutePaths.DATA_LIST}/${SubRoutePaths.DATA_EXPORT}`
    )
      return true;
    else return false;
  }
  return false;
};

/**
 export const dataListLocCheck = (location: Location, wanted: SubRoutePaths) => {

    if (wanted === SubRoutePaths.ALL_DATA) {
        let spilted = location.pathname.split("/");
        if ("/" + spilted[1] ===  MainRoutePaths.DATA_LIST) return true;
        else return false
    }
    else if (wanted === SubRoutePaths.DATA_EXPORT) {
        if (location.pathname === `${MainRoutePaths.DATA_LIST}/${SubRoutePaths.DATA_EXPORT}`) return true;
        else return false;
    }
    return false;
}
 */
