import React from "react";
import Button from "../../../resources/customs/button/Button";
import { mediumButtonSize } from "../../../resources/customs/button/ButtonSC";
import Loader from "../../../resources/customs/loader/Loader";
import { ButtonIcons } from "../../../resources/utils/enums/CommonEnums";
import Table from "../../components/table/Table";
import { privateKeysLabels } from "../../components/table/tableUtils/LabelVars";
import NewPrivateKey from "../../layouts/privateKeysLayouts/newPrivateKey/NewPrivateKey";
import {
  PrivateKeysContainer,
  PrivateKeysWrapper,
  PrivateKeysTable,
  ButtonSection,
  ModalWrapper,
} from "./PrivateKeysSC";
import usePrivateKeys from "./UsePrivateKeys";

const PrivateKeys = () => {
  const { loading, privateKeys, showModal, setShowModal } = usePrivateKeys();
  return (
    <PrivateKeysContainer>
      <PrivateKeysWrapper>
        <PrivateKeysTable>
          {loading ? (
            <Loader scale={1.1} />
          ) : (
            <Table data={privateKeys} headers={privateKeysLabels} />
          )}
            {showModal && <ModalWrapper>
                <NewPrivateKey  
                    closeModal={() => setShowModal(false)}
                />
              
            </ModalWrapper>}
        </PrivateKeysTable>
        <ButtonSection>
          <Button 
          buttonSize={mediumButtonSize} 
          icon={ButtonIcons.UploadIcon}
          onClick={() => setShowModal(true)}
          >
            UPLOAD NEW KEY
          </Button>
          <Button buttonSize={mediumButtonSize} icon={ButtonIcons.DeleteIcons}>
            DELETE KEY
          </Button>
          <Button buttonSize={mediumButtonSize} icon={ButtonIcons.FindReplace}>
            REPLACE KEY
          </Button>
        </ButtonSection>
      </PrivateKeysWrapper>
    </PrivateKeysContainer>
  );
};

export default PrivateKeys;
