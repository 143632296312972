import { getDateWithoutTime, removeDuplicates } from "../hooks/Commonhooks";
import {
  SensorDayRow,
  SensorRow,
  SingleRowSensorData,
} from "../interfaces/CommonInterfaces";
import TablesStates from "./TableStates";
import _ from "lodash";
import SensorData from "./SensorData";
interface SelectedRowsProps {
  allSensorSelected: SensorRow[];
  daySelected: SensorDayRow[];
  signleRowData: SingleRowSensorData[];
}

class SelectedRows implements SelectedRowsProps {
  public allSensorSelected: SensorRow[] = [];
  public daySelected: SensorDayRow[] = [];
  public signleRowData: SingleRowSensorData[] = [];

  constructor(obj?: SelectedRowsProps) {
    if (obj) {
      const { allSensorSelected, daySelected, signleRowData } = obj;
      this.allSensorSelected = allSensorSelected;
      this.daySelected = daySelected;
      this.signleRowData = signleRowData;
    }
  }

  private regulateSelectedRows = () => {
    for (let i of this.signleRowData) {
      let sensor = i.allRowData.Name;
      let day = getDateWithoutTime(new Date(i.allRowData.Time));
      let foundInDay = _.find(
        this.daySelected,
        (dayRow) => dayRow.day === day && dayRow.sesnsorId === sensor
      );
      let foundInSensor = _.find(
        this.allSensorSelected,
        (sensorRow) => sensorRow.sesnsorId === sensor
      );
      if (foundInDay || foundInSensor) this.signleRowData = [];
    }
    for (let i of this.daySelected) {
      let sensor = i.sesnsorId;
      let found = _.find(
        this.allSensorSelected,
        (sensorRow) => sensorRow.sesnsorId === sensor
      );
      if (found) this.daySelected = [];
    }
  };

  public updateItems = (tablesStates: TablesStates) => {
    const { allSensorTable, dayTable, singleInfoTable } = tablesStates;
    if (allSensorTable)
      this.allSensorSelected = removeDuplicates(
        [
          ...this.allSensorSelected,
          ...(allSensorTable.multipleSelectedItems as SensorRow[]),
        ],
        "sesnsorId"
      );
    if (dayTable)
      this.daySelected = removeDuplicates(
        [
          ...this.daySelected,
          ...(dayTable.multipleSelectedItems as SensorDayRow[]),
        ],
        "day"
      );
    if (singleInfoTable)
      this.signleRowData = removeDuplicates(
        [
          ...this.signleRowData,
          ...(singleInfoTable.multipleSelectedItems as SingleRowSensorData[]),
        ],
        "Time"
      );
    this.regulateSelectedRows();
  };

  public canExport = (): boolean => {
    let returned = false;
    if (this.allSensorSelected.length > 0) returned = true;
    if (this.daySelected.length > 0) returned = true;
    if (this.signleRowData.length > 0) returned = true;
    return returned;
  };

  public deleteAllSensor = (value: SensorRow) => {
    this.allSensorSelected = _.filter(
      this.allSensorSelected,
      (val) =>
        val.sesnsorId !== value.sesnsorId || val.sensorMac !== value.sensorMac
    );
  };

  public deleteDayData = (value: SensorDayRow) => {
    this.daySelected = _.filter(
      this.daySelected,
      (val) => val.day !== value.day || val.sesnsorId !== value.sesnsorId
    );
  };

  public deleteSingleData = (value: SingleRowSensorData) => {
    this.signleRowData = _.filter(
      this.signleRowData,
      (val) =>
        val.Time !== value.Time || val.allRowData.Name !== value.allRowData.Name
    );
  };

  public convertToSensorData = (): SensorData[] => {
    let returned: SensorData[] = [];
    for (let i of this.allSensorSelected) {
      returned = [...returned, ...i.allSensorData];
    }
    for (let i of this.daySelected) {
      returned = [...returned, ...i.allDayData.map((v) => v.allRowData)];
    }
    for (let i of this.signleRowData) {
      returned = [...returned, i.allRowData];
    }
    return returned;
  };

  public reset = () => {
    this.allSensorSelected = [];
    this.daySelected = [];
    this.signleRowData = [];
  }
}

export default SelectedRows;
