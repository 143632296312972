import styled from "styled-components";
import ANIMATIONS from "../../../resources/utils/styles/OtherCss";

export let Header = styled.h1`
  ${(props) => props.theme.font}
`;

export let Text = styled.p`
  ${(props) => props.theme.font}
`;

export const HomePageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  ${ANIMATIONS.basicpageAnimation};
  display: flex;
  justify-content: center;
  align-items: center;
`;



export const DataBlocksWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: grid;
  position: relative;
  //position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 130px;
  row-gap: 5px;
  gap: 10px;
  transition: all 400ms ease-out;

 
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
