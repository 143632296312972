import { Row } from "../Table";


export const userListLabels : Row[]= [
    {
        valueProp: 'userId',
        label: 'UID'
    },
    {
        valueProp: 'email',
        label: 'EMAIL'
    },
    {
        valueProp: 'name',
        label: 'NAME'
    },
    {
        valueProp: 'company',
        label: 'COMPANY'
    },
    {
        valueProp: 'role',
        label: 'SUBSCRIPTION'
    }
]


export const sensorListLabels : Row[] = [
    {
        valueProp: 'sesnsorId',
        label: 'SENSOR ID'
    },
    {
        valueProp: 'sensorMac',
        label: 'MAC ADDRESS'
    },
    {
        valueProp: 'latestDataTime',
        label: 'LATEST DATA TIME'
    },
]

export const dayListLabels : Row[] = [
    {
        valueProp: 'day',
        label: 'DAY'
    },
    {
        valueProp: 'latestDataTime',
        label: 'LATEST DATA OF THE DAY'
    },
]

export const singleDataLabels : Row[] = [
    {
        valueProp: 'Time',
        label: 'TIME'
    },
    {
        valueProp: 'Humidity',
        label: 'HUMIDITY'
    },
    {
        valueProp: 'Temperature',
        label: 'TEMPERATURE'
    },
    {
        valueProp: 'Movement',
        label: 'MOVEMENT'
    },
    
]


export const privateKeysLabels : Row[] = [
    {
        valueProp: 'customer',
        label: 'CUSTOMER'
    },
    {
        valueProp: 'privateKeyHex',
        label: 'PRIVATE KEY HEX'
    }
]

