import { useState } from "react";

interface DropDownMenus {
  sensorRows: boolean;
  dayRows: boolean;
  singleRows: boolean;
}

export enum DropDownEnums {
  SensorRows = "sensorRows",
  DayRows = "dayRows",
  SingleRows = "singleRows",
}

interface UseDataExport {
    dropDowns: DropDownMenus;
    controlDropDowns: (dropDown: DropDownEnums) => void
}

const useDataExport = () :UseDataExport  => {
  const [dropDowns, setDropDowns] = useState<DropDownMenus>({
    sensorRows: true,
    dayRows: true,
    singleRows: true,
  });

  const controlDropDowns = (dropDown: DropDownEnums) => {
    setDropDowns((current) => {
      return { ...current, [dropDown]: !current[dropDown] };
    });
  };

  return {dropDowns, controlDropDowns}
};

export default useDataExport;
