import { Link } from "react-router-dom";
import styled from "styled-components";

export let Header = styled.h1`
  ${(props) => props.theme.font}
`;

export const NavbarContainer = styled.div`
  flex: 1;
  height: 100%;
  min-width: 300px;
  background: #363740;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavbarWrapper = styled.div`
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

`;

export const LogoDisplay = styled.img`
  width: 133px;
  height: 30px;
  margin-top: 40px;
  //DDE2FF
`;

export const NavbarList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
 
`;

export const NavbarItem: any = styled.div`
  width: 100%;
  height: 40px;
  transition: all 350ms ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: ${({ chosen }: any) => chosen && "#fff"};
  }

  &:hover {
    background: rgba(159, 162, 180, 0.08);

    .icon--nav {
      color: #dde2ff;
    }
  }
  &:hover ${Header} {
    color: white;
  }
  .icon--nav {
    color: ${({ chosen }: any) =>
      chosen ? "#fff" : "rgba(159, 162, 180, 0.4)"};
    margin-left: 25px;
    margin-right: 30px;
    transform: scale(0.8);
  }
`;
export const NavbarIcon = styled.svg`
  width: 30px;
  height: 30px;
  margin-right: 25px;
`;
export const NavbarLink = styled(Link)`
  width: 100%;
  height: 100%;
  transition: all 350ms ease-out;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    border-left: 4px solid #dde2ff;
  }
`;

// Text related styled components
