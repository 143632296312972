
import { RadioContainer } from './RadioSC'
import CircleIcon from '@mui/icons-material/Circle';
 

interface RadioPoprs {
    value: any;
    selectedValue: any;
    onClick: (value: any,...other: any) => void;
    className?: string;
}   

const Radio = ({value, selectedValue, onClick,className} :RadioPoprs) => {
   
  return (
    <RadioContainer 
        className={className ? className : '--noclass'}
        onClick={() => (selectedValue === null || selectedValue !== value) ? onClick(value): null}
    >
        {value ===  selectedValue && 
        <CircleIcon className='circle--icon' />  }
    </RadioContainer>
  )
}

export default Radio