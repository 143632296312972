import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import Dashboard from '../pages/dashboardPage/Dashboard'
import LoginPage from '../pages/loginPage/LoginPage'
import { AppParentContainer, GlobalStyle } from './AppSC'

const App = () => {
  const {isLoggedIn} = AppContext();

  const location = useLocation();
  useEffect(() => {
      console.log(location)
  },[location]) 

  return (
    <AppParentContainer>
      {/***
       * 
       * 
      <GlobalStyle /> 
      <h4 style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        The app is at maitenance
      </h4>
       * 
       * 
      ***/}
        
      <GlobalStyle /> 
      {isLoggedIn ? <Dashboard /> : <LoginPage /> } 
    </AppParentContainer>
  )
}

export default App