import styled from "styled-components";

export const RadioContainer   = styled.div`
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1.4px solid #1151f5;
  transition: all 300ms ease-out;
  border-radius: 5px;
  &:hover {
    opacity: 0.8;
    background: #1151f5;
    border:1px solid #ffffff; 
    & > .circle--icon {
        color: white;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  & >.circle--icon {
    font-size: 15px;
    transition: all 300ms ease-out;
    color: #1151f5;
  }
`;
